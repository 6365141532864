import React from 'react';

const PageBanner = (props) => {

    return (
        <>
            <section
                className="w-full bg-cover bg-center py-[0px] md:py-[20px]"
                style={{ backgroundImage: `url(${props.backgroundImage})` }} // Dynamically set background image from props
            >
                <div className="container mx-auto p-4 h-full items-center">
                    <div className="grid grid-cols-1 gap-4 items-center">
                        <div className='text-center pt-[100px] pb-[10px] md:pb-[50px] md:pt-[130px]'>
                            <h1 className='text-white text-[35px] font-[Roboto] font-medium leading-tight mb-1 md:mb-5 md:text-[40px] lg:text-[45px]'>
                                {props.heading}
                            </h1>
                            <p className='text-white text-[16px] font-[Roboto] leading-tight mb-4 md:text-[18px] md:text-[24px]'>
                                {props.paragraph}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PageBanner;



