import React from 'react';

const VisitTable = () => {
  // Dummy visit data
  const visits = [
    { id: 1, date: '2024-10-01', landingUrl: 'https://example.com/page1', referrerUrl: 'https://referrer.com/pageA' },
    { id: 2, date: '2024-10-05', landingUrl: 'https://example.com/page2', referrerUrl: 'https://referrer.com/pageB' },
    { id: 3, date: '2024-10-10', landingUrl: 'https://example.com/page3', referrerUrl: 'https://referrer.com/pageC' },
  ];

  return (
    <div className="border border-gray-300 w-full">
      {visits.length === 0 ? (
        <p className="text-gray-600 text-center">You have no visits.</p>
      ) : (
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-600 text-left">
              <th className="py-2 px-4 border-b">ID</th>
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">Landing URL</th>
              <th className="py-2 px-4 border-b">Referrer URL</th>
            </tr>
          </thead>
          <tbody>
            {visits.map((visit) => (
              <tr key={visit.id} className="border-b hover:bg-gray-100">
                <td className="py-2 px-4">{visit.id}</td>
                <td className="py-2 px-4">{visit.date}</td>
                <td className="py-2 px-4">
                  <a href={visit.landingUrl} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">
                    {visit.landingUrl}
                  </a>
                </td>
                <td className="py-2 px-4">
                  <a href={visit.referrerUrl} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">
                    {visit.referrerUrl}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default VisitTable;
