import React, { useState, useEffect } from 'react';
import PageBanner from '../components/PageBanner';
import { NavLink } from 'react-router-dom';
import Header from "../components/Header"
import Footer from "../components/Footer"
import axios from 'axios';
import { IMAGE_URL, REACT_APP_API_PORT } from '../Api';
import Loader from '../Loader';
const AboutUs = () => {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null); // State to hold error messages
    const [apiIsLoading, setapiIsLoading] = useState(false);

      useEffect(() => {
          const fetchData = async () => {
              try {
                setapiIsLoading(true)
                  const response = await axios.get(`${REACT_APP_API_PORT}admin/api/cms/aboutus/show/671a81053cc6e0b0d038e39c`);
                  if (response.data.status) {
                    setapiIsLoading(false)
                      setData(response.data.data);
                  }
                  else {
                    setapiIsLoading(false)
                    setError(null);
                }
              } catch (error) {
                setapiIsLoading(false)
                  console.error('Error fetching data:', error);
              }
          };
  
          fetchData();
      }, []);
  
      if (!data) return <p></p>;

    return (
        
        
        <>
         <Loader isLoading={apiIsLoading}/>
        <title>About Us - Building Muscle & Losing Fat: Tips & Workouts</title>
        <Header/>
            <PageBanner
                heading= {data.sectionOneTitle}
                paragraph={data.sectionOneSubTitle}
                backgroundImage={`${IMAGE_URL}${data.sectionOneBannerImage}`}
            />
            <section>
                <div className="container mx-auto p-4 h-full items-center">
                    <div className="grid grid-cols-1 gap-4 items-center pt-8">
                    <h2 className='text-[#049998] font-[Roboto] font-medium leading-tight text-[24px] lg:text-[30px] text-center'>{data.sectionTwoHeading}</h2>
                    <p
                        className='text-[#333333] text-[16px] font-[Roboto] leading-loose mb-4 md:text-[18px]'
                        dangerouslySetInnerHTML={{ __html: data.sectionTwoDescription }}
                        ></p>
                    </div>
                </div>
            </section>
            <section>
                <div className="container mx-auto p-4 h-full flex items-center">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 flex items-center">
                        <div className='p-8'>
                            <img 
                            //  src='/images/Blue-Out-Of-Office-Announcement-Phone-Screen-Instagram-Post-768x768.png'
                             src={`${IMAGE_URL}${data.sectionTwoImageOne}`}
                             alt='mobile' />
                        </div>
                        <div className='p-8'>
                            <img 
                            // src='/images/Blue-Out-Of-Office-Announcement-Phone-Screen-Instagram-Post-1-1-1024x1024.png' 
                            src={`${IMAGE_URL}${data.sectionTwoImageTwo}`}
                            alt='mobile' />
                        </div>
                        <div className='p-8'>
                            <img 
                            // src='/images/Blue-Out-Of-Office-Announcement-Phone-Screen-Instagram-Post-1-copy-1024x1024.png' 
                            src={`${IMAGE_URL}${data.sectionTwoImageThree}`}
                            alt='mobile' />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container mx-auto p-4 h-full items-center">
                    <div className="grid grid-cols-1 gap-4 items-center pt-8">
                    <h2 className='text-[#049998] font-[Roboto] font-medium leading-tighttext-[24px] text-[24px] lg:text-[30px] text-center'>{data.sectionThreeHeading}</h2>
                    <p
                        className='text-[#333333] text-[16px] font-[Roboto] leading-loose mb-4 md:text-[18px]'
                        dangerouslySetInnerHTML={{ __html: data.sectionThreeDescription }}
                        ></p>
                   </div>
                </div>
            </section>
            <section>
                <div className="container mx-auto p-4 h-full items-center">
                    <div className="grid grid-cols-1 gap-4 items-center pt-8">
                    <h2 className='text-[#049998] font-[Roboto] font-medium leading-tight text-[24px] lg:text-[30px] text-center'>{data.sectionFourHeading}</h2>
                    <p className='text-[#333333] text-[16px] font-[Roboto] leading-loose mb-4'>{data.sectionFourDescription}</p>
                    </div>
                </div>
            </section>
            <section>
                <div className="container mx-auto p-4 h-full w-full items-center">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 flex items-center w-full">
                        <div className='flex justify-center'>
                            <img src={`${IMAGE_URL}${data.sectionFourImageOne}`} alt='mobile' />
                        </div>
                        <div className='flex justify-center'>
                            <img src={`${IMAGE_URL}${data.sectionFourImageTwo}`} alt='mobile' />
                        </div>
                        <div className='flex justify-center'>
                            <img src={`${IMAGE_URL}${data.sectionFourImageThree}`} alt='mobile' />
                        </div>
                    </div>
                    <NavLink to={data.sectionFourButtonLink} className='text-center mt-5 w-full block'> 
                        <button className='text-[16px] uppercase font-[Montserrat] font-bold px-[30px] py-[8px] rounded text-white mt-5 bg-custom-gradient'>{data.sectionFourButtonName}</button>
                    </NavLink>
                </div>
            </section>
            <section>
                <div className="container mx-auto p-4 h-full items-center">
                    <div className="grid grid-cols-1 gap-4 items-center pt-8">
                    <h2 className='text-[#049998] font-[Roboto] font-medium leading-tight text-[24px] lg:text-[30px] text-center'>{data.sectionFiveTitle}</h2>
                    <p className='text-[#333333] text-[16px] font-[Roboto] leading-loose mb-4 text-center'>{data.sectionFiveSubTitle}</p>
                    </div>
                </div>
            </section>
            <section className='pb-10'>
                <div className="container mx-auto p-4 h-full w-full ">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 flex w-full">
                        <div className=''>
                            <h3 className='text-[#049998] font-[Roboto] font-medium leading-tight text-[24px] mb-4'>{data.sectionFiveHeadingOne}</h3>
                            <p
                            className='text-[#333333] text-[16px] font-[Roboto] leading-loose mb-4 md:text-[18px]'
                            dangerouslySetInnerHTML={{ __html: data.sectionFiveDescriptionOne }}
                            ></p>
                        </div>
                        <div className=''>
                            <h3 className='text-[#049998] font-[Roboto] font-medium leading-tight text-[24px] mb-4'>{data.sectionFiveHeadingTwo}</h3>
                            <p
                            className='text-[#333333] text-[16px] font-[Roboto] leading-loose mb-4 md:text-[18px]'
                            dangerouslySetInnerHTML={{ __html: data.sectionFiveDescriptionTwo }}
                            ></p>
                        </div>
                        <div className=''>
                            <h3 className='text-[#049998] font-[Roboto] font-medium leading-tight text-[24px] mb-4'>{data.sectionFiveHeadingThree}</h3>
                            <p
                            className='text-[#333333] text-[16px] font-[Roboto] leading-loose mb-4 md:text-[18px]'
                            dangerouslySetInnerHTML={{ __html: data.sectionFiveDescriptionThree }}
                            ></p>
                        </div>
                    </div>
                    <NavLink to={data.sectionFiveButtonLink} className='text-center mt-2 w-full block'> 
                        <button className='text-[16px] uppercase font-[Montserrat] font-bold px-[30px] py-[8px] rounded text-white mt-5 bg-custom-gradient'>{data.sectionFiveButtonName}</button>
                    </NavLink>
                </div>
            </section>
        <Footer/>
        </>
    )
}

export default AboutUs;