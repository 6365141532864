import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars } from "react-icons/fa6";
import { IoMdCloseCircle } from "react-icons/io";

const Header = () => {
  useEffect(() => {
    const toggleOpen = document.getElementById('toggleOpen');
    const toggleClose = document.getElementById('toggleClose');
    const collapseMenu = document.getElementById('collapseMenu');
    const header = document.getElementById('header');

    const handleClick = () => {
      if (collapseMenu.style.display === 'block') {
        collapseMenu.style.display = 'none';
      } else {
        collapseMenu.style.display = 'block';
      }
    };

    const handleScroll = () => {
      if (window.scrollY > 50) {
        header.style.backgroundColor = 'black';
      } else {
        header.style.backgroundColor = '#0000007d';
      }
    };

    if (toggleOpen) {
      toggleOpen.addEventListener('click', handleClick);
    }
    if (toggleClose) {
      toggleClose.addEventListener('click', handleClick);
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listeners on unmount
    return () => {
      if (toggleOpen) {
        toggleOpen.removeEventListener('click', handleClick);
      }
      if (toggleClose) {
        toggleClose.removeEventListener('click', handleClick);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const active = ({ isActive }) => {
    return {
      color: isActive ? "#0eb290" : "white",
    };
  };

  return (
    <header id="header" className='section w-full fixed top-0 z-50 bg-[#0000007d]'>
      <div className='container m-auto'>
        <div className='row'>
          <div className='flex px-4 font-[sans-serif] min-h-[80px] tracking-wide relative w-full transition-all duration-300'>
            <div className='flex flex-wrap items-center gap-5 w-full'>
              <NavLink to="/">
                <img src="/images/bodyrecomp-logo.png" alt="logo" className='w-40 md:w-60 max-lg:mr-5' />
              </NavLink>

              <div id="collapseMenu" className='max-lg:hidden lg:!flex lg:ml-auto max-lg:before:fixed max-lg:before:bg-black max-lg:before:opacity-50 max-lg:before:inset-0 max-lg:before:z-50'>
                <button id="toggleClose" className='lg:hidden fixed top-6 right-3 z-[100] rounded-full bg-white'>
                    <IoMdCloseCircle className='text-[30px]' />
                </button>

                <ul className='lg:flex gap-2 md:gap-0 lg:gap-0 max-lg:space-y-3 max-lg:fixed max-lg:bg-custom-gradient max-lg:w-1/2 max-lg:min-w-[100%] max-lg:top-[80px] max-lg:left-0 max-lg:shadow-md max-lg:overflow-auto z-50'>
                  {/* Menu Items */}
                  <li className='max-lg:border-b max-lg:border-white max-lg:border-opacity-20 max-lg:py-3 px-3 mt-0'>
                    <NavLink to='/' style={active} className='hover:text-[#007bff] text-white font-semibold block text-[15px]'>Home</NavLink>
                  </li>
                  <li className='max-lg:border-b max-lg:border-white max-lg:border-opacity-20 max-lg:py-3 px-3 mt-0'>
                      <NavLink to='/about-us' style={active}
                      className='hover:text-[#007bff] text-white font-semibold block text-[15px]'>About Us
                      </NavLink>
                  </li>
                  <li className='max-lg:border-b max-lg:border-white max-lg:border-opacity-20 max-lg:py-3 px-3 mt-0'>
                      <NavLink to='/training' style={active}
                      className='hover:text-[#007bff] text-white font-semibold block text-[15px]'>Training
                      </NavLink>
                  </li>
                  <li className='max-lg:border-b max-lg:border-white max-lg:border-opacity-20 max-lg:py-3 px-3 mt-0'>
                      <NavLink to='/nutrition' style={active}
                      className='hover:text-[#007bff] text-white font-semibold block text-[15px]'>Nutrition
                      </NavLink>
                  </li>
                  <li className='max-lg:border-b max-lg:border-white max-lg:border-opacity-20 max-lg:py-3 px-3 mt-0'>
                      <NavLink to='/body-recomposition-meal-workout-plans/bodyrecomp-affiliate-marketing/' style={active}
                      className='hover:text-[#007bff] text-white font-semibold block text-[15px]'>Become An Affiliate
                      </NavLink>
                  </li>
                  <li className='max-lg:border-b max-lg:border-white max-lg:border-opacity-20 max-lg:py-3 px-3 mt-0'>
                      <NavLink to='/blog' style={active}
                      className='hover:text-[#007bff] text-white font-semibold block text-[15px]'>Blog
                      </NavLink>
                  </li>
                  <li className='max-lg:border-b max-lg:border-white max-lg:border-opacity-20 max-lg:py-3 px-3 mt-0'>
                      <NavLink to='/contact-us' style={active}
                      className='hover:text-[#007bff] text-white font-semibold block text-[15px]'>Contact Us
                      </NavLink>
                  </li>
                  <li className='max-lg:border-b max-lg:border-white max-lg:border-opacity-20 max-lg:py-3 px-3 mt-0'>
                      <NavLink to='/affiliate-account/' style={active}
                      className='hover:text-[#007bff] text-white font-semibold block text-[15px]'>Login
                      </NavLink>
                  </li>
                  <li className='md:hidden block max-lg:border-b max-lg:border-white max-lg:border-opacity-20 max-lg:py-3 px-3 mt-0'>
                      <NavLink to='/affiliate-registration/' style={active}
                      className='hover:text-[#007bff] text-white font-semibold block text-[15px]'>Signup
                      </NavLink>
                  </li>
                </ul>
              </div>

              <div className='flex items-center max-lg:ml-auto space-x-6'>
                <button className="hidden md:block px-4 py-2 text-[15px] rounded font-semibold text-black hover:text-white bg-white hover:bg-[#000] transition-all ease-in-out duration-300">
                  <NavLink to="/affiliate-registration/" className="font-semibold block text-[15px]">Signup</NavLink>
                </button>

                <button id="toggleOpen" className='lg:hidden'>
                    <FaBars className='text-white text-[25px]' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
