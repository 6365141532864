// import React, { useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// // Register the components for Chart.js
// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const ChartComponent = () => {
//   const [period, setPeriod] = useState('daily');

//   // Sample data for different periods
//   const dataOptions = {
//     daily: {
//       labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [120, 200, 300, 150, 400, 320, 250],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [50, 70, 90, 60, 100, 80, 95],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [300, 400, 450, 380, 500, 450, 420],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//     weekly: {
//       labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [700, 1400, 900, 1300],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [300, 450, 200, 500],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [1500, 1800, 2000, 2200],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//     monthly: {
//       labels: ['Month 1', 'Month 2', 'Month 3', 'Month 4'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [3000, 5000, 4500, 6000],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [1000, 1200, 1400, 1300],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [8000, 9000, 10000, 11000],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//   };

//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         display: false, // Hide default legend
//       },
//       title: {
//         display: true,
//         text: 'Daily, Weekly, and Monthly Records for Visits, Commissions, and Earnings',
//       },
//     },
//   };

//   return (
//     <div className="w-full p-6 border border-gray-300 shadow my-2">
//       <div className="flex justify-between mb-2">
//         <div className="flex items-center space-x-2">
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#00AADC] rounded-full"></span>
//             <span className="ml-1 text-sm">Visits</span>
//           </div>
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#F39C12] rounded-full"></span>
//             <span className="ml-1 text-sm">Commissions</span>
//           </div>
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#26A387] rounded-full"></span>
//             <span className="ml-1 text-sm">Earnings</span>
//           </div>
//         </div>
//         <select
//           value={period}
//           onChange={(e) => setPeriod(e.target.value)}
//           className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
//         >
//           <option value="daily">Daily</option>
//           <option value="weekly">Weekly</option>
//           <option value="monthly">Monthly</option>
//         </select>
//       </div>
//       <Line data={dataOptions[period]} options={options} />
//     </div>
//   );
// };

// export default ChartComponent;


// import React, { useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// // Register the components for Chart.js
// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const ChartComponent = () => {
//   const [period, setPeriod] = useState('daily');

//   // Sample data for different periods
//   const dataOptions = {
//     daily: {
//       labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [120, 200, 300, 150, 400, 320, 250],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [50, 70, 90, 60, 100, 80, 95],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [300, 400, 450, 380, 500, 450, 420],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//     weekly: {
//       labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [700, 1400, 900, 1300],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [300, 450, 200, 500],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [1500, 1800, 2000, 2200],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//     monthly: {
//       labels: ['Month 1', 'Month 2', 'Month 3', 'Month 4'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [3000, 5000, 4500, 6000],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [1000, 1200, 1400, 1300],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [8000, 9000, 10000, 11000],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false, // Disable aspect ratio maintenance
//     plugins: {
//       legend: {
//         display: false, // Hide default legend
//       },
//       title: {
//         display: true,
//         text: 'Daily, Weekly, and Monthly Records for Visits, Commissions, and Earnings',
//       },
//     },
//   };

//   return (
//     <div className="w-full p-6 border border-gray-300 shadow my-2">
//       <div className="flex justify-between mb-2">
//         <div className="flex items-center space-x-2">
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#00AADC] rounded-full"></span>
//             <span className="ml-1 text-sm">Visits</span>
//           </div>
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#F39C12] rounded-full"></span>
//             <span className="ml-1 text-sm">Commissions</span>
//           </div>
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#26A387] rounded-full"></span>
//             <span className="ml-1 text-sm">Earnings</span>
//           </div>
//         </div>
//         <select
//           value={period}
//           onChange={(e) => setPeriod(e.target.value)}
//           className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
//         >
//           <option value="daily">Daily</option>
//           <option value="weekly">Weekly</option>
//           <option value="monthly">Monthly</option>
//         </select>
//       </div>
//       <div className="h-[300px]"> {/* Set maximum height here */}
//         <Line data={dataOptions[period]} options={options} />
//       </div>
//     </div>
//   );
// };

// export default ChartComponent;


// import React, { useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// // Register the components for Chart.js
// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const ChartComponent = () => {
//   const [period, setPeriod] = useState('daily');

//   // Sample data for different periods
//   const dataOptions = {
//     daily: {
//       labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [120, 200, 300, 150, 400, 320, 250],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [50, 70, 90, 60, 100, 80, 95],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [300, 400, 450, 380, 500, 450, 420],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//     weekly: {
//       labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [700, 1400, 900, 1300],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [300, 450, 200, 500],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [1500, 1800, 2000, 2200],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//     monthly: {
//       labels: ['Month 1', 'Month 2', 'Month 3', 'Month 4'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [3000, 5000, 4500, 6000],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [1000, 1200, 1400, 1300],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [8000, 9000, 10000, 11000],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false, // Disable aspect ratio maintenance
//     plugins: {
//       legend: {
//         display: false, // Hide default legend
//       },
//       title: {
//         display: true,
//         text: 'Daily, Weekly, and Monthly Records for Visits, Commissions, and Earnings',
//       },
//     },
//   };

//   return (
//     <div className="w-full p-4 md:p-6 border border-gray-300 shadow my-2">
//       <div className="flex justify-between mb-2 flex-wrap">
//         <div className="flex items-center space-x-2 mb-2 md:mb-0">
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#00AADC] rounded-full"></span>
//             <span className="ml-1 text-sm">Visits</span>
//           </div>
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#F39C12] rounded-full"></span>
//             <span className="ml-1 text-sm">Commissions</span>
//           </div>
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#26A387] rounded-full"></span>
//             <span className="ml-1 text-sm">Earnings</span>
//           </div>
//         </div>
//         <select
//           value={period}
//           onChange={(e) => setPeriod(e.target.value)}
//           className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
//         >
//           <option value="daily">Daily</option>
//           <option value="weekly">Weekly</option>
//           <option value="monthly">Monthly</option>
//         </select>
//       </div>
//       <div className="h-[600px] md:h-[300px]"> {/* Set maximum height here for mobile responsiveness */}
//         <Line data={dataOptions[period]} options={options} />
//       </div>
//     </div>
//   );
// };

// export default ChartComponent;


// import React, { useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// // Register the components for Chart.js
// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const ChartComponent = () => {
//   const [period, setPeriod] = useState('daily');

//   // Sample data for different periods
//   const dataOptions = {
//     daily: {
//       labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [120, 200, 300, 150, 400, 320, 250],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [50, 70, 90, 60, 100, 80, 95],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [300, 400, 450, 380, 500, 450, 420],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//     weekly: {
//       labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [700, 1400, 900, 1300],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [300, 450, 200, 500],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [1500, 1800, 2000, 2200],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//     monthly: {
//       labels: ['Month 1', 'Month 2', 'Month 3', 'Month 4'],
//       datasets: [
//         {
//           label: 'Visits',
//           data: [3000, 5000, 4500, 6000],
//           borderColor: '#00AADC',
//           backgroundColor: 'rgba(0, 170, 220, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Commissions',
//           data: [1000, 1200, 1400, 1300],
//           borderColor: '#F39C12',
//           backgroundColor: 'rgba(243, 156, 18, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//         {
//           label: 'Earnings',
//           data: [8000, 9000, 10000, 11000],
//           borderColor: '#26A387',
//           backgroundColor: 'rgba(38, 163, 135, 0.2)',
//           tension: 0.4,
//           fill: true,
//         },
//       ],
//     },
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false, // Disable aspect ratio maintenance
//     plugins: {
//       legend: {
//         display: false, // Hide default legend
//       },
//       title: {
//         display: true,
//         text: 'Daily, Weekly, and Monthly Records for Visits, Commissions, and Earnings',
//       },
//     },
//   };

//   return (
//     <div className="w-full p-4 md:p-6 border border-gray-300 shadow my-2">
//       <div className="flex justify-between mb-2 flex-wrap">
//         <div className="flex items-center space-x-2 flex-wrap">
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#00AADC] rounded-full"></span>
//             <span className="ml-1 text-sm">Visits</span>
//           </div>
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#F39C12] rounded-full"></span>
//             <span className="ml-1 text-sm">Commissions</span>
//           </div>
//           <div className="flex items-center">
//             <span className="w-3 h-3 bg-[#26A387] rounded-full"></span>
//             <span className="ml-1 text-sm">Earnings</span>
//           </div>
//         </div>
//         <select
//           value={period}
//           onChange={(e) => setPeriod(e.target.value)}
//           className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
//         >
//           <option value="daily">Daily</option>
//           <option value="weekly">Weekly</option>
//           <option value="monthly">Monthly</option>
//         </select>
//       </div>
//       <div className="h-[250px] md:h-[300px]"> {/* Responsive height */}
//         <Line data={dataOptions[period]} options={options} />
//       </div>
//     </div>
//   );
// };

// export default ChartComponent;

import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the components for Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ChartComponent = () => {
  const [period, setPeriod] = useState('daily');

  // Sample data for different periods
  const dataOptions = {
    daily: {
      labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
      datasets: [
        {
          label: 'Visits',
          data: [120, 200, 300, 150, 400, 320, 250],
          borderColor: '#00AADC',
          backgroundColor: 'rgba(0, 170, 220, 0.2)',
          tension: 0.4,
          fill: true,
        },
        {
          label: 'Commissions',
          data: [50, 70, 90, 60, 100, 80, 95],
          borderColor: '#F39C12',
          backgroundColor: 'rgba(243, 156, 18, 0.2)',
          tension: 0.4,
          fill: true,
        },
        {
          label: 'Earnings',
          data: [300, 400, 450, 380, 500, 450, 420],
          borderColor: '#26A387',
          backgroundColor: 'rgba(38, 163, 135, 0.2)',
          tension: 0.4,
          fill: true,
        },
      ],
    },
    weekly: {
      labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
      datasets: [
        {
          label: 'Visits',
          data: [700, 1400, 900, 1300],
          borderColor: '#00AADC',
          backgroundColor: 'rgba(0, 170, 220, 0.2)',
          tension: 0.4,
          fill: true,
        },
        {
          label: 'Commissions',
          data: [300, 450, 200, 500],
          borderColor: '#F39C12',
          backgroundColor: 'rgba(243, 156, 18, 0.2)',
          tension: 0.4,
          fill: true,
        },
        {
          label: 'Earnings',
          data: [1500, 1800, 2000, 2200],
          borderColor: '#26A387',
          backgroundColor: 'rgba(38, 163, 135, 0.2)',
          tension: 0.4,
          fill: true,
        },
      ],
    },
    monthly: {
      labels: ['Month 1', 'Month 2', 'Month 3', 'Month 4'],
      datasets: [
        {
          label: 'Visits',
          data: [3000, 5000, 4500, 6000],
          borderColor: '#00AADC',
          backgroundColor: 'rgba(0, 170, 220, 0.2)',
          tension: 0.4,
          fill: true,
        },
        {
          label: 'Commissions',
          data: [1000, 1200, 1400, 1300],
          borderColor: '#F39C12',
          backgroundColor: 'rgba(243, 156, 18, 0.2)',
          tension: 0.4,
          fill: true,
        },
        {
          label: 'Earnings',
          data: [8000, 9000, 10000, 11000],
          borderColor: '#26A387',
          backgroundColor: 'rgba(38, 163, 135, 0.2)',
          tension: 0.4,
          fill: true,
        },
      ],
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Disable aspect ratio maintenance
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
      title: {
        display: true,
        text: 'Daily, Weekly, and Monthly Records for Visits, Commissions, and Earnings',
        font: {
          size: 16, // Font size for larger screens
        },
      },
    },
  };

  return (
    <div className="w-full p-4 md:p-6 border border-gray-300 shadow my-2">
      <div className="flex justify-between mb-2 flex-wrap">
        <div className="flex items-center space-x-2 flex-wrap">
          <div className="flex items-center">
            <span className="w-3 h-3 bg-[#00AADC] rounded-full"></span>
            <span className="ml-1 text-sm">Visits</span>
          </div>
          <div className="flex items-center">
            <span className="w-3 h-3 bg-[#F39C12] rounded-full"></span>
            <span className="ml-1 text-sm">Commissions</span>
          </div>
          <div className="flex items-center">
            <span className="w-3 h-3 bg-[#26A387] rounded-full"></span>
            <span className="ml-1 text-sm">Earnings</span>
          </div>
        </div>
        <select
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          className="border border-gray-300 rounded-md p-2 mt-2 w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-blue-400" // Full width on mobile
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>
      <div className="h-[250px] md:h-[300px]"> {/* Responsive height */}
        <Line data={dataOptions[period]} options={options} />
      </div>
    </div>
  );
};

export default ChartComponent;

