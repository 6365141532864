// import React from 'react';
// import { useNavigate } from 'react-router-dom'; // If you're using React Router for navigation
// import Header from '../components/Header';
// import Footer from '../components/Footer';

// const PageNotFound = () => {
//     const navigate = useNavigate(); // Hook for accessing history

//     return (
//         <>
//         <Header/>
//         <div className="flex items-center justify-center h-screen bg-gray-100">
//             <div className="text-center">
//                 <h1 className="text-6xl font-bold text-red-600">404</h1>
//                 <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 mt-4">
//                     Oops! Page Not Found
//                 </h2>
//                 <p className="mt-2 text-gray-600">
//                     The page you are looking for does not exist. 
//                 </p>
//                 <button 
//                     onClick={() => navigate('/')} // Redirect to home page
//                     className="mt-6 px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
//                 >
//                     Go Back to Home
//                 </button>
//             </div>
//         </div>
//         <Footer/>
//         </>
//     );
// };

// export default PageNotFound;


import React from 'react';
import { useNavigate } from 'react-router-dom'; // If you're using React Router for navigation
import Header from '../components/Header';
import Footer from '../components/Footer';

const PageNotFound = () => {
    const navigate = useNavigate(); // Hook for accessing history

    return (
        <>
            <Header />
            <div className="flex items-center justify-center h-screen bg-gray-100">
                <div className="text-center">
                    <h1 className="text-6xl font-bold text-red-600">404</h1>
                    <h2 className="text-2xl md:text-5xl font-semibold text-gray-800 my-4">
                        This page could not be found!
                    </h2>
                    <p className="mt-2 text-gray-600">
                        We are sorry. But the page you are looking for is not available.
                    </p>
                    <p className="mt-2 text-gray-600">
                        Perhaps you can try a new search.
                    </p>

                    <button
                        type="button"
                        onClick={() => navigate('/')}
                        className="my-3 Montserrat  bg-custom-gradient border-2 border-[#049998] text-white px-4 py-2 rounded-sm hover:text-black transition duration-200"
                    >
                        Back to HomePage
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PageNotFound;
