import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import ContactUs from './pages/ContactUs';
import Nutrition from './pages/Nutrition';
import Training from './pages/Training';
import AffiliateRegistration from './pages/AffiliateRegistration.jsx';
import AffiliateLogin from './pages/AffiliateLogin.jsx';
import BecomeAffiliate from './pages/BecomeAffiliate.jsx';
import PageNotFound from './pages/PageNotFound.jsx';
import Dashboard from './pages/dashboard/Dashboard.jsx';
import AffilliateResetPassword from './pages/AffilliateResetPassword.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import TermsAndCondition from './pages/TermsAndCondition.jsx';
function App() {
  return (
    <>
   
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog-detail/:id" element={<BlogDetail />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/nutrition" element={<Nutrition />} />
      <Route path="/training" element={<Training />} />
      <Route path="/affiliate-account/" element={<AffiliateLogin />} />
      <Route path="/affiliate-registration/" element={<AffiliateRegistration />} />
      <Route path="/affiliate-reset-password/" element={<AffilliateResetPassword />} />
      <Route path="/body-recomposition-meal-workout-plans/bodyrecomp-affiliate-marketing/:optionId?" element={<BecomeAffiliate />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndCondition />} />

      
    </Routes>
    
    </>
  );
}

export default App;