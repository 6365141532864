// BackdropLoader.js
import React from 'react';

const Loader = ({ isLoading }) => {
  if (!isLoading) return null; // Do not render anything if not loading

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="loader">
        <div className="w-16 h-16 border-4 border-[#ffb907] border-t-transparent rounded-full animate-spin"></div>
      </div>
    </div>
  );
};

export default Loader;
