import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { FaTwitter, FaFacebook, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import { IMAGE_URL, REACT_APP_API_PORT } from '../Api';

const BlogDetail = () => {
    const { id } = useParams(); // Get the blog id from the URL
    console.log(id)
    const [blogData, setBlogData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBlogData = async () => {
            try {
                const response = await axios.get(`${REACT_APP_API_PORT}admin/api/cms/blog/show/${id}`);
                if (response.data.data) {
                    setBlogData(response.data.data); // Set the blog data
                } else {
                    setError("No data found");
                }
            } catch (err) {
                setError(err.message || "An error occurred while fetching blog data.");
            } finally {
                setLoading(false);
            }
        };

        fetchBlogData();
    }, [id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' }); // Enable smooth scrolling
        }, 100);

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, []);

   

    if (error) {
        return <div>Error: {error}</div>; // Show error message
    }

    // Ensure blogData has the expected structure
    if (!blogData || !blogData.blog_title || !blogData.blog_content) {
        return <div>No blog data available.</div>;
    }

    const formattedDate = new Date(blogData.createdAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    return (
        <>
            <Header />
            <div className="px-4 md:px-48" style={{ marginTop: '120px' }}>
                {/* Blog Title */}
                <h1 className="text-4xl font-bold text-center text-gray-800 mb-4" style={{ color: '#333' }}>
                    {blogData.blog_title}
                </h1>
                <br /><hr /><br />
                {/* Category, Date, and Comments */}
                <div className="flex justify-center items-center space-x-4 text-gray-600 text-md mb-8">
                    <span className="flex items-center space-x-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                    </svg>
                        <span>{blogData.blog_category}</span>
                    </span>
                    <span className="flex items-center space-x-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                        <span>{formattedDate}</span>
                    </span>
                    <span className="flex items-center space-x-1">
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776" />
                    </svg>
                        <span>{blogData.blog_comments} Comments</span>
                    </span>
                </div>
                <hr /><br />

                {blogData.blog_image && (
    <div className="flex items-center justify-center">
        <img 
            src={`${IMAGE_URL}${blogData.blog_image}`} 
            alt="Blog" 
            className="max-h-[400px] max-w-[400px] w-full h-auto object-cover" // Max dimensions with responsive width and height
        />
    </div>
)}<br/>


                

                {/* Blog Content */}
                <div className="text-gray-800 leading-relaxed" dangerouslySetInnerHTML={{ __html: blogData.blog_content }} />

                <hr /><br />

                <div className="flex justify-between items-center mb-4">
                    <p className="font-bold text-xl md:text-xl" style={{ color: '#333' }}>
                        PLEASE SHARE THIS
                    </p>
                    <div className='flex gap-2'>
                        <NavLink to="https://twitter.com/i/flow/login?redirect_after_login=%2Fbodyrecomp_app" 
                                 className="text-[#708e9f] hover:text-[#fff] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                            <FaTwitter className='text-[16px]' />
                        </NavLink>
                        <NavLink to="https://www.facebook.com/BodyRecompApp/?_rdr" 
                                 className="text-[#708e9f] hover:text-[#fff] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                            <FaFacebook className='text-[16px]' />
                        </NavLink>
                        <NavLink to="" 
                                 className="text-[#708e9f] hover:text-[#fff] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                            <FaLinkedin className='text-[16px]' />
                        </NavLink>
                        <NavLink to="" 
                                 className="text-[#708e9f] hover:text-[#fff] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                            <FaWhatsapp className='text-[16px]' />
                        </NavLink>
                    </div>
                </div>
                <hr /><br />
            </div>
            <Footer />
        </>
    );
};

export default BlogDetail;
