import React, { useState, useEffect } from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import PageBanner2 from '../components/PageBanner2';
import ImageBoxSection from './ImageBoxSection';
import { FaArrowRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { IMAGE_URL, REACT_APP_API_PORT } from '../Api';
import Loader from '../Loader';
const Nutrition = () => {
 

      const [data, setData] = useState(null);
      const [imageList, setImages] = useState([]);
      const [error, setError] = useState(null); // State to hold error messages
      const [apiIsLoading, setapiIsLoading] = useState(false);
  
      useEffect(() => {
          const fetchData = async () => {
              try {
                setapiIsLoading(true)
                  const response = await axios.get(`${REACT_APP_API_PORT}admin/api/cms/nutrition/show/6725f3292cf7213912df8694`);
                  if (response.data.status) {
                    setapiIsLoading(false)
                      setData(response.data.data);
                     
  
                       let imagedata = [
                        { src : response.data.data.sectionTwoHeadingImageOne , title:response.data.data.sectionTwoHeadingOne },
                        { src : response.data.data.sectionTwoHeadingImageTwo , title:response.data.data.sectionTwoHeadingTwo },
                        { src : response.data.data.sectionTwoHeadingImageThree , title:response.data.data.sectionTwoHeadingThree },
                        { src : response.data.data.sectionTwoHeadingImageFour , title:response.data.data.sectionTwoHeadingFour },
                        { src : response.data.data.sectionTwoHeadingImageFive , title:response.data.data.sectionTwoHeadingFive },
                        { src : response.data.data.sectionTwoHeadingImageSix , title:response.data.data.sectionTwoHeadingSix }
                    ];

                    imagedata = imagedata.map(image => ({
                        src: `${IMAGE_URL}${image.src}`, // Merge IMAGE_URL with the image src
                        title: image.title // Keep the title as it is
                    }));

                    setImages(imagedata)
                  
                  }
                  else {
                    setapiIsLoading(false)
                    setError(null);
                }
              } catch (error) {
                setapiIsLoading(false)
                  console.error('Error fetching data:', error);
              }
          };
  
          fetchData();
      }, []);
      
    const [rotateStyles, setRotateStyles] = useState(Array(imageList.length).fill({}));


    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Enable smooth scrolling
            });
        }, 100); // Adjust the timeout as needed

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, []);

    

    const handleMouseMove = (index) => (e) => {
      const imageElement = e.currentTarget;
      const rect = imageElement.getBoundingClientRect();
      const x = e.clientX - rect.left; // Mouse X relative to image
      const y = e.clientY - rect.top;  // Mouse Y relative to image
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;
      
      const rotationY = ((x - centerX) / centerX) * 30; // Adjust rotation sensitivity (Y-axis)
      const rotationX = ((y - centerY) / centerY) * -30; // Adjust rotation sensitivity (X-axis)
  
      // Update the rotation style for the hovered image
      setRotateStyles((prevStyles) => {
        const newStyles = [...prevStyles];
        newStyles[index] = {
          transform: `rotateX(${rotationX}deg) rotateY(${rotationY}deg) scale(1.05)`,
          transition: 'transform 0.1s ease-out',
        };
        return newStyles;
      });
    };
  
    const handleMouseLeave = (index) => () => {
      // Reset the transformation for the hovered image
      setRotateStyles((prevStyles) => {
        const newStyles = [...prevStyles];
        newStyles[index] = {
          transform: 'rotateX(0deg) rotateY(0deg) scale(1)',
          transition: 'transform 0.5s ease-in-out',
        };
        return newStyles;
      });
    };
    if (!data) return <p></p>;
    
    return (
        <>
         <Loader isLoading={apiIsLoading}/>
        <title>Nutrition - Meal Plans For Every Goal and Taste</title>
        <Header/>
        <PageBanner2
                heading={data.sectionOneTitle}
                backgroundImage="/images/nut.png" // Pass the image URL as a prop
                overlay={true}
                height={90}
            />
        <section className='bg-black'>
    <div className='container mx-auto px-4'>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-0 p-8">
        {
            imageList && imageList.map((item,index)=>{
                return <>
                <div 
                 onMouseMove={handleMouseMove(index)} // Pass the index
                 onMouseLeave={handleMouseLeave(index)} // Pass the index
                className="relative group overflow-hidden ">
                    <img
                        src={item.src}
                        alt="Image 1"
                        className="w-full h-auto transition-transform duration-500 ease-in-out transform group-hover:scale-105"
                       
                        style={rotateStyles[index]}
                    />
                    {/* Gradient Overlay */}
                    <div
                        className="absolute inset-0 flex items-center justify-center transition-opacity duration-500 ease-in-out group-hover:opacity-0"
                        style={{
                            backgroundImage: 'linear-gradient(to left, rgba(0, 173, 117, 0.7), rgba(11, 124, 202, 0.7))',
                        }}
                    >
                        <h1 className='text-white text-[35px] font-[Roboto] font-medium leading-tight mb-1 md:mb-5 '>
                        {item.title}
                        </h1>
                    </div>
                </div>
                </>
            })
        }
            

           
        </div>
    </div>
        </section>

   

        <section className='bg-black text-white'>
            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* First Part - Text and Button */}
                    <div className="flex flex-col justify-start items-end md:py-4 md:pl-6 ">
                        <div className=''>
                        <span className="Abeezee text-xl md:text-2xl lg:text-3xl font-bold mb-3">
                        {data.sectionThreeSideHeading}
                        </span>
                        <p
                        className='text-[#fff] text-[16px] font-[Roboto] leading-loose mb-4 md:text-[18px]'
                        dangerouslySetInnerHTML={{ __html: data.sectionThreeSideDescription }}
                        ></p>
                        <div className='flex gap-2'>
                        <NavLink to={data.sectionThreeAppleButtonLink} target='_black'>
                            <img src={`${IMAGE_URL}${data.sectionThreeAppleImage}`} alt="App Store" className='w-[150px] max-lg:mr-5' />
                        </NavLink> 
                        <NavLink to={data.sectionThreeGoogleButtonLink} target='_black'>
                            <img src={`${IMAGE_URL}${data.sectionThreeGoogleImage}`} alt="Play store" className='w-[150px] max-lg:mr-5 mb-4' />
                        </NavLink>
                        
                        </div>
                        <div className="flex justify-start">
                        <NavLink to={data.sectionThreeButtonLink} className='text-center w-full block'> 
                            <button className="flex items-center bg-custom-gradient text-white py-2 px-4 rounded hover:bg-custom-gradient transition duration-300">
                                <FaArrowRight className="mr-2" />
                                {data.sectionThreeButtonName}
                            </button>
                            </NavLink>
                        </div>
                        </div>
                    </div>

                    {/* Second Part - Image */}
                    <div className="flex justify-center items-center md:p-12">
                        <img
                            // src="/images/frame-1.png" // Replace with your image path
                            src={`${IMAGE_URL}${data.sectionThreeImage}`} // Replace with your image path
                            alt="Nutrition Plan"
                            className="w-full h-auto rounded shadow-lg"
                        />
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    )
}

export default Nutrition;