import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { IoMdMail } from "react-icons/io";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import axios from 'axios';
import Loader from './Loader';
import { REACT_APP_API_PORT } from '../Api';
import Swal from 'sweetalert2';
import '../css/swal.css'

const Footer = () => {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null); // State to hold error messages
    const [email, setEmail] = useState('');
    const [apiIsLoading, setapiIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setapiIsLoading(true)
                const response = await axios.get(`${REACT_APP_API_PORT}admin/api/cms/contactus/show/67287df347debec7c527cb72`);
                if (response.data.status) {
                    setapiIsLoading(false)
                    setData(response.data.data);
                }
                else {
                    setapiIsLoading(false)
                    setError(null);
                }
            } catch (error) {
                setapiIsLoading(false)
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); 

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setapiIsLoading(true)
            const response = await axios.post(
                `${REACT_APP_API_PORT}app/api/auth/addEmailMailchimp`,
                { email: email },
                { headers: { 'Content-Type': 'application/json' } }
            );
            if (response.data.status) {
                setapiIsLoading(false)
                Swal.fire(
                    {
                    // title:'Contact Us',
                    text:'Subscribe Successfully',
                    icon:'success',
                    confirmButtonColor: '#0a82be',
                    timer: 3000, // Set timer to auto-close after 3 seconds (3000 ms)
                    willClose: () => {
                        // After the alert closes, refresh the page
                        window.location.reload();
                    }
                  })
            } else {
                setapiIsLoading(false)
                Swal.fire(
                    {
                    // title:'Contact Us',
                    text:'Subscribe Failed',
                    icon:'error',
                    confirmButtonColor: '#d33',
                    timer: 3000, // Set timer to auto-close after 3 seconds (3000 ms)
                    willClose: () => {
                        // After the alert closes, refresh the page
                        window.location.reload();
                    }
                  })
            }
            }catch (error) {
                setapiIsLoading(false);
                console.error(error); // Log the error details
            }
    };

    if (!data) return <p></p>;

    return (
        <>
        <Loader isLoading={apiIsLoading}/>
            <section id='footer' className="w-full bg-cover bg-center py-[20px] bg-[#222]">
                <div className="container mx-auto p-4 h-full flex ">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 flex  w-full">
                    <div>
                    <img src="/images/bodyrecomp-logo.png" alt="logo"
                        className='w-48 max-lg:mr-5 mb-4' />
                        <NavLink to="mailto:recomp@bodyrecomp.app"
                         className='flex items-center text-white text-[14px]' ><IoMdMail /> : {data.sectionTwoEmailAddress}</NavLink>
                        <div className='flex mt-4 gap-2 mb-4'>
                            <NavLink to={data.sectionTwoTwitterLink} className="text-[#708e9f] hover:text-[#fff] bg-[#263036] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                                <FaTwitter className='text-[16px]'  />
                            </NavLink>
                            <NavLink to={data.sectionTwoFacebookLink} className="text-[#708e9f] hover:text-[#fff] bg-[#263036] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                                <FaFacebook className='text-[16px]'  />
                            </NavLink>
                            <NavLink to={data.sectionTwoInstagramLink} className="text-[#708e9f] hover:text-[#fff] bg-[#263036] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                                <FaInstagram className='text-[16px]'  />
                            </NavLink>
                            <NavLink to={data.sectionTwoTiktokLink} className="text-[#708e9f] hover:text-[#fff] bg-[#263036] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                                <FaTiktok className='text-[16px]'  />
                            </NavLink>
                        </div>
                        <div className='flex gap-2'>
                        <NavLink to="https://apps.apple.com/us/app/bodyrecomp/id6444131195" target='_black'>
                            <img src="/images/appstore.png" alt="App Store" className='w-[120px] max-lg:mr-5' />
                        </NavLink> 
                        <NavLink to="https://play.google.com/store/apps/details?id=com.bodyrecomp" target='_black'>
                            <img src="/images/googleplay.png" alt="Play store" className='w-[120px] max-lg:mr-5 mb-4' />
                        </NavLink>
                        </div>
                    </div>
                    
                    <div className="mt-1 md:mt-0">
                        <h4 className=' text-white uppercase text-[13px] border-l-[3px] border-[#00ad75] pl-2 leading-[12px] mb-5'>PRODUCTS</h4>
                        <NavLink to="/nutrition" className=" hover:text-[#00ad75] text-white block text-[13px] mb-3 leading-[20px] ">Nutrition</NavLink>
                        <NavLink to="/training" className=" hover:text-[#00ad75] text-white block text-[13px] leading-[20px]">Training</NavLink>
                    </div>
                    <div className="mt-3 md:mt-0">
                        <h4 className='text-white uppercase text-[13px] border-l-[3px] border-[#00ad75] pl-2 leading-[12px] mb-5'>COMPANY</h4>
                        <NavLink to="/contact-us" className="hover:text-[#00ad75] text-white block text-[13px] mb-3 leading-[20px]">Support</NavLink>
                        <NavLink to="/about-us" className="hover:text-[#00ad75] text-white block text-[13px] mb-3 leading-[20px]">About Us</NavLink>
                        <NavLink to="/blog" className="hover:text-[#00ad75] text-white block text-[13px] mb-3 leading-[20px]">Blog</NavLink>
                        <NavLink to="/contact-us" className="hover:text-[#00ad75] text-white block text-[13px] leading-[20px]">Contact Us</NavLink>
                    </div>
                    <div className="mt-3 md:mt-0">
                        <h4 className='text-white uppercase text-[13px] border-l-[3px] border-[#00ad75] pl-2 leading-[12px] mb-5'>LEGAL</h4>
                        <NavLink to="/privacy-policy" className="hover:text-[#00ad75] text-white block text-[13px] mb-3 leading-[20px]">Privacy Policy</NavLink>
                        <NavLink to="/terms-and-conditions" className="hover:text-[#00ad75] text-white block text-[13px] leading-[20px]">Terms & Conditions</NavLink>
                        <form id="" onSubmit={handleSubmit} className="mt-6 md:mt-5 max-w-[270px]" >
                            <div className="space-y-4">
                                <p>
                                    <label className="block text-sm font-medium text-white">Email:
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Your email address"
                                            required
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded text-[12px] sm:text-sm bg-transparent"
                                        />
                                    </label>
                                </p>
                                <p>
                                    <input
                                        type="submit"
                                        value="Subscribe"
                                        className="px-5 py-3 bg-blue-500 text-white font-semibold rounded uppercase bg-custom-gradient text-[12px]"
                                    />
                                </p>
                            </div>
                        </form>

                    </div>
                    </div>
                </div>
            </section>
            <section id='footer-bottom' className="w-full bg-cover bg-center p-[20px] bg-[#1b1b1b]">
                <p id='copyright' className='text-[12px] text-white text-center'>© Copyright Koa Ventures LLC.</p>
            </section>
            </>
    )
}

export default Footer;