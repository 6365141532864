import React, { useState, useEffect } from 'react';
import PageBanner from '../components/PageBanner';
import { NavLink } from 'react-router-dom';
import Header from "../components/Header"
import Footer from "../components/Footer"
import axios from 'axios';
import { REACT_APP_API_PORT } from '../Api';
import Loader from '../Loader';

const PrivacyPolicy = () => {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null); // State to hold error messages
    const [apiIsLoading, setapiIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setapiIsLoading(true);
                const response = await axios.get(`${REACT_APP_API_PORT}app/api/privacyterms/list`);
                if (response.data.status) {
                    setapiIsLoading(false);
                    // Accessing the first item in the data array and extracting the privacy content
                    setData(response.data.data[0].privacy); 
                } else {
                    setapiIsLoading(false);
                    setError('No data found');
                }
            } catch (error) {
                setapiIsLoading(false);
                console.error('Error fetching data:', error);
                setError('An error occurred while fetching the data.');
            }
        };

        fetchData();
    }, []);

    if (apiIsLoading) return <Loader isLoading={apiIsLoading} />;
    if (error) return <p>{error}</p>; // Display error if any

    return (
        <>
            <title>Privacy Policy</title>
            <Header />
            <section>
                <div className="container mx-auto p-4 h-full items-center" style={{marginTop:"80px"}}>
                    <div className="grid grid-cols-1 gap-4 items-center pt-8">
                        <h2 className='text-[#049998] font-[Roboto] font-medium leading-tight text-[24px] lg:text-[30px] text-center'>
                            Privacy Policy
                        </h2>
                        {/* Render the privacy content using dangerouslySetInnerHTML */}
                        <div
                            className='text-[#333333] text-[16px] font-[Roboto] leading-loose mb-4 md:text-[18px]'
                            dangerouslySetInnerHTML={{ __html: data }}
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default PrivacyPolicy;
