// import React from 'react';

// const PageBanner2 = (props) => {
//     return (
//         <section
//             className="relative w-full bg-cover bg-center py-[0px] md:py-[20px]"
//             style={{ backgroundImage: `url(${props.backgroundImage})` }} // Dynamically set background image from props
//         >
//             {/* Overlay effect, conditionally rendered based on overlay prop */}
//             {props.overlay && (
//                 <div className="absolute inset-0 bg-black opacity-50"></div>
//             )}
//             <div className="container mx-auto p-4 h-full flex flex-col justify-center items-center relative z-20">
//                 <div className="text-center pt-[100px] pb-[10px] md:pb-[50px] md:pt-[130px]">
//                     <h1 className='text-white text-[35px] font-[Roboto] font-medium leading-tight mb-1 md:mb-5 md:text-[40px] lg:text-[45px]'>
//                         {props.heading}
//                     </h1>
//                     <p className='text-white text-[16px] font-[Roboto] leading-tight mb-4 md:text-[18px] md:text-[24px]'>
//                         {props.paragraph}
//                     </p>
//                 </div>
//             </div>
//         </section>
//     );
// }

// export default PageBanner2;


import React from 'react';

const PageBanner2 = (props) => {
    // Dynamically manage height: use height prop if available, otherwise default to static height
    const sectionHeight = props.height ? `${props.height}vh` : '380px'; // Default to 500px if height is not provided

    return (
        <section
            className="relative w-full bg-cover bg-center py-[0px] md:py-[20px]"
            style={{
                backgroundImage: `url(${props.backgroundImage})`, // Dynamically set background image
                height: sectionHeight, // Apply dynamic height
                backgroundPosition:"top",
                backgroundSize:"cover"
            }}
        >
            {/* Overlay effect, conditionally rendered based on overlay prop */}
            {props.overlay && (
                <div className="absolute inset-0 bg-black opacity-80"></div>
            )}
            <div className="container mx-auto p-4 h-full flex flex-col justify-center items-center relative z-20">
                <div className="text-center pt-[100px] pb-[10px] md:pb-[50px] md:pt-[130px]">
                    <h1 className='text-white text-[35px] font-[Roboto] font-medium leading-tight mb-1 md:mb-5 md:text-[40px] lg:text-[45px]'>
                        {props.heading}
                    </h1>
                    <p className='text-white text-[16px] font-[Roboto] leading-tight mb-4 md:text-[18px] md:text-[24px]'>
                        {props.paragraph}
                    </p>
                </div>
            </div>
        </section>
    );
}

export default PageBanner2;
