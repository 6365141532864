import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import HomeBanner from '../components/HomeBanner';
import { IoMdCheckmarkCircle } from "react-icons/io";
import Header from "../components/Header"
import Footer from "../components/Footer"
import axios from 'axios';
import { IMAGE_URL, REACT_APP_API_PORT } from '../Api';
import Aos from 'aos'
import 'aos/dist/aos.css';
import Loader from '../Loader';
const Home = () => {
    useEffect(() => {
        Aos.init({
          duration: 1000,
          once: true,  
        });
      }, []);

      const [data, setData] = useState(null);
      const [error, setError] = useState(null); // State to hold error messages
      const [apiIsLoading, setapiIsLoading] = useState(false);

      useEffect(() => {
          const fetchData = async () => {
              try {
                    setapiIsLoading(true)
                  const response = await axios.get(`${REACT_APP_API_PORT}admin/api/cms/home/show/6718dad756da10d4a8191fab`);
                  if (response.data.status) {
                    setapiIsLoading(false)
                      setData(response.data.data);
                  }
                  else {
                    setapiIsLoading(false)
                    setError(null);
                }
              } catch (error) {
                setapiIsLoading(false)
                  console.error('Error fetching data:', error);
              }
          };
  
          fetchData();
      }, []);
  
      if (!data) return <p></p>;
    return (
        <>
        <Loader isLoading={apiIsLoading}/>
        <title>BodyRecomp Body Recomposition Meal & Workout Plans</title>
        <Header />
          <HomeBanner />
            <section className="w-full bg-cover bg-center py-4 overflow-hidden" style={{maxWidth: "100%",
            paddingLeft: "1rem", // or appropriate value
            paddingRight: "1rem",
            overflowX:"hidden!important"
            }}> {/* Add overflow-hidden here */}
                <div className="container mx-auto p-2 md:p-4 h-full flex items-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 flex items-center">
                    {/* Image with AOS slide-right animation */}
                    <div data-aos="slide-right" className="p-8">
                        <img
                        src={`${IMAGE_URL}${data.sectionTwoSideImage}`}
                        alt="banner image"
                        className="w-full h-auto"
                        />
                    </div>

                    {/* Text content with AOS slide-left animation */}
                    <div data-aos="slide-left">
                        <h1 className="text-gray-800 text-[22px] md:text-[30px] lg:text-[40px] uppercase font-[Montserrat] font-bold leading-tight mb-5 px-0">
                        {data.sectionTwoHeading}
                        </h1>
                        <div>
                     
                        <ul>
                            <li className="DMSans flex items-center text-[18px] md:text-[16px] lg:text-[18px] leading-[32px] mb-2 text-[#4a4a4a]">
                                <IoMdCheckmarkCircle className="mr-2 text-[#039C88] text-[20px] shrink-0" />
                                {data.sectionTwoLineOne}
                            </li>
                            <li className="DMSans flex items-center text-[18px] md:text-[16px] lg:text-[18px] leading-[32px] mb-2 text-[#4a4a4a]">
                                <IoMdCheckmarkCircle className="mr-2 text-[#039C88] text-[20px] shrink-0" />
                                {data.sectionTwoLineTwo}
                            </li>
                            <li className="DMSans flex items-center text-[18px] md:text-[16px] lg:text-[18px] leading-[32px] mb-2 text-[#4a4a4a]">
                                <IoMdCheckmarkCircle className="mr-2 text-[#039C88] text-[20px] shrink-0" />
                                {data.sectionTwoLineThree}
                            </li>
                            <li className="DMSans flex items-center text-[18px] md:text-[16px] lg:text-[18px] leading-[32px] mb-2 text-[#4a4a4a]">
                                <IoMdCheckmarkCircle className="mr-2 text-[#039C88] text-[20px] shrink-0" />
                                {data.sectionTwoLineFour}
                            </li>
                        </ul>


                        <NavLink to={data.sectionTwoButtonLink}>
                            <button className="text-[22px] md:text-[22px] uppercase font-[Montserrat] font-bold px-[30px] py-[8px] rounded text-white mt-5 bg-custom-gradient">
                            {data.sectionTwoButtonName}
                            </button>
                        </NavLink>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section className="w-full bg-cover bg-center py-4">
      <div className="container mx-auto p-4 h-full flex items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 flex items-center">
          
          <div className='order-2 md:order-1 ' data-aos="slide-right" >
            <h1 className='text-gray-800 text-[22px] md:text-[30px] lg:text-[40px] uppercase font-[Montserrat] font-bold leading-tight mb-5'>
            {data.sectionThreeHeading}
            </h1>
           
            <ul>
    <li className="DMSans flex items-center text-[18px] md:text-[16px] lg:text-[18px] leading-[32px] mb-2 text-[#4a4a4a]">
        <IoMdCheckmarkCircle className="mr-2 text-[#039C88] text-[20px] shrink-0" />
        {data.sectionThreeLineOne}
    </li>
    <li className="DMSans flex items-center text-[18px] md:text-[16px] lg:text-[18px] leading-[32px] mb-2 text-[#4a4a4a]">
        <IoMdCheckmarkCircle className="mr-2 text-[#039C88] text-[20px] shrink-0" />
        {data.sectionThreeLineTwo}
    </li>
    <li className="DMSans flex items-center text-[18px] md:text-[16px] lg:text-[18px] leading-[32px] mb-2 text-[#4a4a4a]">
        <IoMdCheckmarkCircle className="mr-2 text-[#039C88] text-[20px] shrink-0" />
        {data.sectionThreeLineThree}
    </li>
    <li className="DMSans flex items-center text-[18px] md:text-[16px] lg:text-[18px] leading-[32px] mb-2 text-[#4a4a4a]">
        <IoMdCheckmarkCircle className="mr-2 text-[#039C88] text-[20px] shrink-0" />
        {data.sectionThreeLineFour}
    </li>
</ul>

            <NavLink to={data.sectionThreeButtonLink}>
              <button className='text-[22px] md:text-[22px] uppercase font-[Montserrat] font-bold px-[30px] py-[8px] rounded text-white mt-5 bg-custom-gradient'>
              {data.sectionThreeButtonName}
              </button>
            </NavLink>
          </div>

          {/* Image with AOS Slide-Left and Zoom-In after scrolling */}
          <div data-aos="slide-left" data-aos-once="true" className="order-1 md:order-2 p-8">
            <img
              src={`${IMAGE_URL}${data.sectionThreeSideImage}`}
              alt="banner image"
              className="w-full h-auto"
              data-aos="zoom-in"  
            />
          </div>

        </div>
      </div>
            </section>
            <section
            data-aos="zoom-in-down" 
            className="w-full bg-cover bg-center py-[100px] " style={{ backgroundImage: `url(${IMAGE_URL}${data.sectionFourImage})` }}>
                <div className="container mx-auto p-4 h-full flex items-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 flex items-center">
                    <div>
                        <h1 className='text-white text-[30px] font-[Roboto] font-medium leading-tight mb-5 max-w-[600px]'>{data.sectionFourHeading}</h1>
                        <p className='text-white text-[14px] font-[Roboto] leading-tight mb-4 max-w-[600px]'>{data.sectionFourDescription}</p>
                        
                        
                    </div>
                    
                    <div className="">
                    </div>
                    </div>
                </div>
            </section>
        <Footer />
        </>
    )
}

export default Home;