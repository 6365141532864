import React from 'react'

function CommissionTable() {
    const commissions = [
        { id: 1, date: '2024-10-01', type: 'Sale', amount: '$100.00', status: 'Completed' },
        { id: 2, date: '2024-10-05', type: 'Refund', amount: '$50.00', status: 'Refunded' },
        { id: 3, date: '2024-10-10', type: 'Sale', amount: '$200.00', status: 'Pending' },
      ];
  return (
    <>
        <div className="border  w-full ">
      {commissions.length === 0 ? (
        <p className="text-gray-600 text-center">You have no commissions.</p>
      ) : (
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-600 text-left">
              <th className="py-2 px-4 border-b">ID</th>
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">Type</th>
              <th className="py-2 px-4 border-b">Amount</th>
              <th className="py-2 px-4 border-b">Status</th>
            </tr>
          </thead>
          <tbody>
            {commissions.map((commission) => (
              <tr key={commission.id} className="border-b hover:bg-gray-100">
                <td className="py-2 px-4">{commission.id}</td>
                <td className="py-2 px-4">{commission.date}</td>
                <td className="py-2 px-4">{commission.type}</td>
                <td className="py-2 px-4">{commission.amount}</td>
                <td className="py-2 px-4">{commission.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
    </>
  )
}

export default CommissionTable