import React, { useState, useEffect } from 'react';
import PageBanner from '../components/PageBanner';
import { NavLink } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageBanner2 from '../components/PageBanner2';
import axios from 'axios';
import { IMAGE_URL, REACT_APP_API_PORT } from '../Api';
import Loader from '../Loader';

const BlogPost = ({ post }) => {
    // Format the createdAt date
    const formattedDate = new Date(post.createdAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const stripHtml = (html) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || "";
      };
      
      const originalContent = post.blog_content || '<em>Content not available</em>';
      
      // Strip HTML tags and then truncate to 100 characters
      const plainTextContent = stripHtml(originalContent);
      const truncatedContent = plainTextContent.length > 200
        ? plainTextContent.substring(0, 200) + '...'
        : plainTextContent;

    return (
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <h2 className="text-[#333333] font-bold text-xl md:text-2xl mb-2 hover:text-[rgb(4_153_152/var(--tw-text-opacity))]">
                {post.blog_title}
            </h2>
            <div className="text-sm text-gray-600 flex items-center space-x-2 mb-4">
                <span className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                    </svg>
                    {post.blog_category}
                </span>
                
                <span className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    {formattedDate}
                </span>
                
                <span className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776" />
                    </svg>
                    {post.blog_comments} Comments
                </span>
            </div>

            <p
  className="text-gray-700 text-base mb-4"
  dangerouslySetInnerHTML={{
    __html: truncatedContent,
  }}
/>

            <NavLink to={`/blog-detail/${post._id}`} className="inline-block bg-white text-gray-700 text-sm px-3 py-1.5 border border-[#333] hover:text-[rgb(4_153_152_/_var(--tw-text-opacity))] hover:border-[rgb(4_153_152_/_var(--tw-text-opacity))]">
                Continue Reading &rarr;
            </NavLink>
        </div>
    );
};

const Blog = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 3;
    const [error, setError] = useState(null); // State to hold error messages
      const [apiIsLoading, setapiIsLoading] = useState(false);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                setapiIsLoading(true)
                const response = await axios.get(`${REACT_APP_API_PORT}admin/api/cms/blog/list`);
                // setBlogPosts(response.data.data); // Assuming the API response has a 'data' field with the blog array
                if (response.data.status) {
                    setapiIsLoading(false)
                    setBlogPosts(response.data.data);
                  }
                  else {
                    setapiIsLoading(false)
                    setError(null);
                }
            } catch (error) {
                setapiIsLoading(false)
                console.error("Error fetching blog posts:", error);
            }
        };

        fetchPosts();
    }, []);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);
    const totalPages = Math.ceil(blogPosts.length / postsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    if (!blogPosts) return <p></p>;

    return (
        <>
        <Loader isLoading={apiIsLoading}/>
        <title>Ultimate Guide to Body Recomposition | BodyRecomp.app | Blog</title>
        <Header />
            <PageBanner2
                heading="Blog"
                backgroundImage="/images/blogBanner.png"
            />
            <section className="container mx-auto p-6">
                {currentPosts.map((post) => (
                    <BlogPost key={post._id} post={post} />
                ))}

                {/* Pagination Controls */}
                <div className="flex items-center justify-between mt-6">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="bg-white text-gray-700 px-4 py-2 border border-gray-300 rounded hover:bg-gray-100 disabled:opacity-50"
                    >
                        &larr; Previous
                    </button>

                    <div className="flex space-x-2">
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentPage(index + 1)}
                                className={`px-3 py-1 border rounded text-gray-700 ${currentPage === index + 1 ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>

                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="bg-white text-gray-700 px-4 py-2 border border-gray-300 rounded hover:bg-gray-100 disabled:opacity-50"
                    >
                        Next &rarr;
                    </button>
                </div>
            </section>
        <Footer/>
        </>
    );
};

export default Blog;
