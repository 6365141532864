import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing eye icons from react-icons
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
const AffilliateResetPassword = () => {
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validate = yup.object().shape({
        username: yup.string()
          .required('Username or Email is required')
          .test(
            'username-or-email',
            'Must be a valid username or email address',
            function (value) {
              const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
              const usernameRegex = /^[a-zA-Z0-9._-]{3,20}$/;
              return emailRegex.test(value) || usernameRegex.test(value);
            }
          ),
      });

      const renderError = (message) => (
        <p className="italic text-[#dd1212]">{message}</p>
      );
    

    return (
        <>
        <title>Affiliate Account</title>
        <Header />
        <div>
            <div className='w-full bg-[#F5F5F5] text-black mt-20'>
                <div className='max-w-screen-xl p-10 mx-auto'>
                    <h1 className=' text-[20px] font-[Roboto] text-gray-800 leading-tight md:text-[20px] lg:text-[25px]'>
                        Affiliate Reset Password
                    </h1>
                </div>
            </div>
            <Formik 
              initialValues={{
                username:"",
               }}
              enableReinitialize={true}
              validationSchema={validate}
              onSubmit={(values, actions) => {
                console.log("values",values)
              }}
            >

            {({values, setFieldValue,handleChange,handleBlur}) => (
                <Form>
                    <div className='w-full text-black'>
                        <div className='max-w-screen-xl p-10 mx-auto'>
                            <span className='block text-sm font-[Roboto] text-gray-800 my-4 Montserrat'>Lost your password? Please enter your email address. You will receive a link to create a new password via email.</span>
                                <div className="mb-4">
                                    <label htmlFor="username" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                        Username / Email <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="username"
                                        name='username'
                                        value={values.username}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 "
                                        
                                    />
                                    <ErrorMessage name="username" render={renderError} />
                                </div>

                                <button
                                    type="submit"
                                    className="bg-custom-gradient text-white px-4 py-2 rounded-sm hover:bg-blue-600 transition duration-200"
                                >
                                    RESET PASSWORD
                                </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        </div>
        <Footer/>
        </>
    );
};

export default AffilliateResetPassword;
