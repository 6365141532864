import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/en-gb'; // Importing locale for correct day names (Monday as start of week)

const FilterDateRangeComponent = () => {
  const [selectedFilter, setSelectedFilter] = useState('Past 30 Days');
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // Set the locale for moment (Monday as the start of the week)
  moment.locale('en-gb'); // Adjust based on your needs

  // List of filters with moment.js for date manipulation
  const filters = [
    { name: 'Past 7 Days', range: () => ({ start: moment().subtract(7, 'days').toDate(), end: moment().toDate() }) },
    { name: 'Past 30 Days', range: () => ({ start: moment().subtract(30, 'days').toDate(), end: moment().toDate() }) },
    { name: 'Week to Date', range: () => ({ start: moment().startOf('week').toDate(), end: moment().toDate() }) },
    { name: 'Month to Date', range: () => ({ start: moment().startOf('month').toDate(), end: moment().toDate() }) },
    { name: 'Year to Date', range: () => ({ start: moment().startOf('year').toDate(), end: moment().toDate() }) },
    { name: 'Last Week', range: () => ({ start: moment().subtract(1, 'week').startOf('week').toDate(), end: moment().subtract(1, 'week').endOf('week').toDate() }) },
    { name: 'Last Month', range: () => ({ start: moment().subtract(1, 'month').startOf('month').toDate(), end: moment().subtract(1, 'month').endOf('month').toDate() }) },
    { name: 'Last Year', range: () => ({ start: moment().subtract(1, 'year').startOf('year').toDate(), end: moment().subtract(1, 'year').endOf('year').toDate() }) },
  ];

  // Handle filter selection
  const handleFilterSelection = (filter) => {
    setSelectedFilter(filter.name);
    const { start, end } = filter.range();
    setStartDate(start);
    setEndDate(end);
    setIsCalendarOpen(false); // Close the calendar after selection
  };

  // Open calendar for custom date selection
  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  // By default, set "Past 30 Days" filter when component mounts
  useEffect(() => {
    const { start, end } = filters.find(filter => filter.name === 'Past 30 Days').range();
    setStartDate(start);
    setEndDate(end);
  }, []);

  return (
    <div className="my-2">
      {/* Filter Selection */}
      <div className="relative">
        <button
          onClick={toggleCalendar}
          className="border border-gray-300 rounded-md px-4 py-2 w-full md:w-auto text-sm focus:outline-none"
        >
          {selectedFilter
            ? `${selectedFilter}: ${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}`
            : 'Select Filter'}
        </button>

        {/* Dropdown and Dual Calendar */}
        {isCalendarOpen && (
          <div className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg flex flex-col md:flex-row">
            {/* Filter List */}
            <div className="w-full md:w-1/2 border-r p-4 space-y-2">
              {filters.map((filter) => (
                <button
                  key={filter.name}
                  onClick={() => handleFilterSelection(filter)}
                  className="block w-full text-left p-2 hover:bg-gray-100 rounded-md"
                >
                  {filter.name}
                </button>
              ))}
            </div>

            {/* Dual Calendar for Date Range Selection */}
            <div className="w-full md:w-1/2 p-4">
            <DatePicker
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(dates) => {
                    const [start, end] = dates;
                    setStartDate(start);
                    setEndDate(end);
                }}
                selectsRange
                inline
                monthsShown={2} // Show two months (dual calendar)
                />
            </div>
          </div>
        )}
      </div>

     
    </div>
  );
};

export default FilterDateRangeComponent;
