import React, { useState, useEffect } from 'react';
import PageBanner2 from '../components/PageBanner2';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa'; // Example social media icons
import { NavLink } from 'react-router-dom';
import { FaTiktok } from 'react-icons/fa6';
import Header from "../components/Header"
import Footer from "../components/Footer"
import axios from 'axios';
import { IMAGE_URL, REACT_APP_API_PORT } from '../Api';
import Loader from '../Loader';
import Swal from 'sweetalert2';
import '../css/swal.css'
const ContactUs = () => {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null); // State to hold error messages
    const [apiIsLoading, setapiIsLoading] = useState(false);
    const [apiIsLoading1, setapiIsLoading1] = useState(false);

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [matter, setMatter] = useState("");
    const [subject, setSubject] = useState("");
    const [responseMessage, setResponseMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setapiIsLoading(true)
                const response = await axios.get(`${REACT_APP_API_PORT}admin/api/cms/contactus/show/67287df347debec7c527cb72`);
                if (response.data.status) {
                    setapiIsLoading(false)
                    setData(response.data.data);
                }
                else {
                    setapiIsLoading(false)
                    setError(null);
                }
            } catch (error) {
                setapiIsLoading(false)
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); 

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setapiIsLoading1(true);
            const response = await axios.post(
                `${REACT_APP_API_PORT}app/api/contact/createContactWeb`,
                { fullName, email, phone, subject, matter },
                { headers: { 'Content-Type': 'application/json' } }
            );
            if (response.data.status) {
                setapiIsLoading1(false)
                // setResponseMessage('Successfully submitted!');
                    // Show success message after form submission
                // setShowSuccess(true);

                // Hide the success message after 3 seconds
                Swal.fire(
                    {
                    title:'Contact Us',
                    text:'Form Submit Successfully',
                    icon:'success',
                    confirmButtonColor: '#0a82be',
                    timer: 3000, // Set timer to auto-close after 3 seconds (3000 ms)
                    willClose: () => {
                        // After the alert closes, refresh the page
                        window.location.reload();
                    }
                  })
            } else {
                setapiIsLoading1(false)
                // setResponseMessage('Submission failed.');
            }
        } catch (error) {
            setapiIsLoading1(false)
            // setResponseMessage('An error occurred. Please try again.');
            console.error(error);
        } finally {
            setapiIsLoading1(false)
        }
    };

    if (!data) return <p></p>;

    return (
        <>
        <Loader isLoading={apiIsLoading}/>
        <Loader isLoading={apiIsLoading1}/>
        <title>Contact Us - Your Partner in Fitness Success | BodyRecomp.app</title>
        <Header/>
          <PageBanner2
                heading={data.sectionOneTitle}
                paragraph={data.sectionOneSubTitle}
                backgroundImage={`${IMAGE_URL}${data.sectionOneBannerImage}`}
                overlay={true}
            />
            <div className='bg-header-bg-color bg-black w-full'>
            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* First Part: Working Hours and Contact Info */}
                    <div className=" p-6 rounded-lg">
                        <h2 className="text-3xl font-semibold mb-4 text-white">Working Hours</h2>
                        <p className="mb-4 text-white">
                        {data.sectionTwoWorkingHours}
                        </p>
                        <h3 className="font-medium mb-2 text-white">Email:</h3>
                        <p className="mb-4 text-white">{data.sectionTwoEmailAddress}</p>
                        <h3 className="font-medium mb-2 text-white">{data.sectionTwoAddressOne}</h3>
                        <p className="text-white">{data.sectionTwoAddressTwo}</p>
                        <div className='flex mt-4 gap-2 mb-4'>
                            <NavLink to={data.sectionTwoTwitterLink} className="text-[#708e9f] hover:text-[#fff] bg-[#263036] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                                <FaTwitter className='text-[16px]'  />
                            </NavLink>
                            <NavLink to={data.sectionTwoFacebookLink} className="text-[#708e9f] hover:text-[#fff] bg-[#263036] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                                <FaFacebook className='text-[16px]'  />
                            </NavLink>
                            <NavLink to={data.sectionTwoInstagramLink} className="text-[#708e9f] hover:text-[#fff] bg-[#263036] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                                <FaInstagram className='text-[16px]'  />
                            </NavLink>
                            <NavLink to={data.sectionTwoTiktokLink} className="text-[#708e9f] hover:text-[#fff] bg-[#263036] hover:bg-[#0eb290] transition-all duration-300 ease-in-out flex items-center justify-center h-[40px] w-[40px] rounded">
                                <FaTiktok className='text-[16px]'  />
                            </NavLink>
                        </div>
                    </div>

                    {/* Second Part: Contact Form */}
                    <div className=" p-6 rounded-lg">
                        {/* <h2 className="text-xl text-white font-semibold mb-4">Contact Us</h2> */}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                             
                                <input
                                    type="text"
                                    id="fullName"
                                    onChange={(e) => setFullName(e.target.value)}
                                    className="font-[Robot] w-full bg-[#1A1A1A] text-white border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:custom-gradient"
                                    placeholder="Full Name"
                                    required
                                />
                            </div>
                            <div className="mb-4">

                                <input
                                    type="email"
                                    id="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="font-[Robot] w-full bg-[#1A1A1A] text-white border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:custom-gradient"
                                    placeholder="Your Email"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <input
                                    type="tel"
                                    id="phone"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        // Allow only digits (0-9) and limit length to 12 characters
                                        if (/^\d{0,12}$/.test(value)) {
                                          setPhone(value);
                                        }
                                      }}
                                    className="font-[Robot] w-full bg-[#1A1A1A] text-white border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:custom-gradient"
                                    placeholder="Phone Number"
                                    maxLength="12"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                             
                                <input
                                    type="text"
                                    id="subject"
                                    onChange={(e) => setSubject(e.target.value)}
                                    className="font-[Robot] w-full bg-[#1A1A1A] text-white border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:custom-gradient"
                                    placeholder="Enter Subject"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <textarea
                                    id="message"
                                    onChange={(e) => setMatter(e.target.value)}
                                    className="font-[Robot] w-full bg-[#1A1A1A] text-white border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:custom-gradient"
                                    placeholder="Your Message"
                                    rows="6"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="px-10 py-4 bg-blue-500 text-white font-semibold rounded uppercase bg-custom-gradient text-[12px]"
                            >
                                Submit
                            </button>
                        </form>
                 
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default ContactUs;