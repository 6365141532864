import React, { useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
function BecomeAffiliate() {
  const navigate = useNavigate()
  useEffect(() => {
    const timer = setTimeout(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Enable smooth scrolling
        });
    }, 100); // Adjust the timeout as needed

    return () => clearTimeout(timer); // Cleanup the timer on unmount
}, []);

useEffect(() => {
  Aos.init({
    duration: 1000,
  });
  Aos.refresh();
}, []);
  return (
    <>
    <title>Join BodyRecomp's Affiliate Marketing Program | Turn Your Fitness Passion into Profit</title>
      <section
        className="relative bg-cover bg-center min-h-screen flex items-center "
        style={{ backgroundImage: `url('/images/affilliate_banner.jpeg')` }}
      >
        <div className="absolute inset-0 bg-black opacity-40"></div>

        <div className="relative lg:mt-5 p-10 lg:px-40 rounded-lg w-full max-w-2xl mx-auto flex flex-col items-start lg:max-w-full w-full">

          <img src="/images/bodyrecomp-logo.png" alt="BodyRecomp Logo" className="w-60 mb-5 lg:mb-20" />
          <div className="lg:w-2/3 mt-10">
            <h1 className="text-white text-6xl lg:text-9xl font-bold mb-6 Anton400">
              Become an Affiliate Today
            </h1>

            <p className="text-white text-lg lg:text-xl mb-6 Montserratparagraph">
              If you’re a fitness professional or a content creator passionate about fitness and eager to make a side income, this opportunity is for you. As a part of our team, you’ll help inspire healthier lives and earn from it. Turn your passion into profit with BodyRecomp.
            </p>
          </div>
          <button
            onClick={()=>navigate("/affiliate-registration")}
            type="button"
            data-aos="fade-up"
            className="Montserrat  bg-custom-gradient border-2 border-[#049998] text-white px-5 py-3 rounded-sm hover:text-black transition duration-200"
          >
            Apply Today
          </button>
        </div>
      </section>

      <div className="max-w-screen-xl mx-auto p-4 lg:mt-40 lg:px-10">

        <div className="flex flex-col lg:hidden">

          <div className="flex-1 bg-white rounded-lg p-6 flex flex-col justify-center items-center mb-4">
            <div className="max-w-xs lg:max-w-sm">
              <h1 className="Anton400 text-4xl font-bold mb-4 text-[#10313D] text-center">Generate Passive Income</h1>

              <h2 className="text-2xl font-semibold mb-2 text-center text-[#10313D] Montserrat" >Enrich Lives</h2>
              <p className="mb-4 text-center text-[#989B9E] Montserrat">
                Every time someone signs up through your affiliate link, you're playing a key role in their fitness journey, helping to motivate and guide them towards healthier lifestyles.
              </p>

              <div data-aos="slide-right">
              <h2 className="text-2xl font-semibold mb-2 text-center text-[#10313D] Montserrat" >Performance Bonuses</h2>
              <p className="mb-4 text-center text-[#989B9E] Montserrat" >
                Many affiliate programs reward top-performing affiliates with additional bonuses. So, the more people you help, the more potential earnings you can enjoy.
              </p>
              </div>

              <div data-aos="slide-right">
              <h2 className="text-2xl font-semibold mb-2 text-center text-[#10313D] Montserrat">Lifetime Commissions</h2>
              <p className="mb-4 text-center text-[#989B9E] Montserrat">
                You're not just earning from one-off purchases, but potentially benefiting from subscriptions for as long as your links remain customers.
              </p>
              </div>

              <div data-aos="slide-right">
              <h2 className="text-2xl font-semibold mb-2 text-center text-[#10313D] Montserrat">Sustainable Earnings</h2>
              <p className="text-center text-[#989B9E] Montserrat">
                With each referral, you're building a steady stream of income that can provide financial security. This income not only rewards your hard work but also empowers you to invest more in your passion to help others on their fitness journeys.
              </p>
              </div>
            </div>
          </div>

          <div className="container mx-auto px-20 mb-4">
            <button
              type="button"
              className="Montserrat bg-custom-gradient border-2 border-[#049998] text-white px-4 py-2 rounded-sm hover:text-black transition duration-200"
            >
              Apply Today!
            </button>
          </div>


        </div>


        <div className="hidden lg:flex lg:flex-row">

          <div className="flex-1 relative mb-4 lg:mb-0 h-96 lg:h-auto lg:mr-4 z-50">
            <img
              src="/images/dumble_blur_bg.jpeg"
              alt="Fitness"
              className="w-full h-full object-cover "
            />

            <div className="absolute inset-0 bg-black opacity-20"></div>

            <div className="absolute inset-0 flex items-center justify-center text-white">
              <img
                src="/images/affiliate_section2_mobile.png"
                alt="Fitness"
                className="w-1/2 h-1/2 object-cover rounded-lg"
              />
            </div>
          </div>


          <div className="flex-1 bg-white rounded-lg p-6 flex flex-col justify-center items-center h-auto">
            <div className="max-w-xs lg:max-w-sm">
              <h1 className="Anton400 text-5xl font-bold mb-4 text-[#10313D] text-center">Generate Passive Income</h1>

              <h2 className="text-2xl font-semibold mb-2 text-center text-[#10313D] Montserrat">Enrich Lives</h2>
              <p className="mb-4 text-center text-gray-500 Montserratparagraph">
                Every time someone signs up through your affiliate link, you're playing a key role in their fitness journey, helping to motivate and guide them towards healthier lifestyles.
              </p>

              <div data-aos="slide-right">
              <h2 className="text-2xl font-semibold mb-2 text-center text-[#10313D] Montserrat">Performance Bonuses</h2>
              <p className="mb-4 text-center  text-gray-500 Montserratparagraph">
                Many affiliate programs reward top-performing affiliates with additional bonuses. So, the more people you help, the more potential earnings you can enjoy.
              </p>
              </div>

              <div data-aos="slide-right">
              <h2 className="text-2xl font-semibold mb-2 text-center text-[#10313D] Montserrat">Lifetime Commissions</h2>
              <p className="mb-4 text-center  text-gray-500 Montserratparagraph">
                You're not just earning from one-off purchases, but potentially benefiting from subscriptions for as long as your links remain customers.
              </p>
              </div>

              <div data-aos="slide-right">
              <h2 className="text-2xl font-semibold mb-2 text-center text-[#10313D] Montserrat">Sustainable Earnings</h2>
              <p className="text-center  text-gray-500 Montserratparagraph">
                With each referral, you're building a steady stream of income that can provide financial security. This income not only rewards your hard work but also empowers you to invest more in your passion to help others on their fitness journeys.
              </p>
              </div>
            </div>
          </div>


        </div>
        <div className="container mx-auto px-20 mt-4 mb-40 hidden lg:block">
          <button
          onClick={()=>navigate("/affiliate-registration")}
            type="button"
            className="Montserrat bg-custom-gradient border-2 border-[#049998] text-white px-4 py-2 rounded-sm hover:text-black transition duration-200"
          >
            Apply Today!
          </button>
        </div>
      </div>

      <div className="flex justify-center text-white md:hidden">
        <img
          src="/images/affiliate_section2_mobile.png"
          alt="Fitness"
          className="w-1/2 h-1/2 object-cover rounded-lg relative top-[80px]"
        />
      </div>


      <section className=" mx-auto py-20 px-5 bg-[#F1F2F3]">
        <div className='container mx-auto lg:py-40'>
          <h2 className="Anton400 text-4xl md:text-5xl font-bold mb-12 text-[#10313D] text-center">How it works is so simple</h2>


          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

            <div className="text-center">
              <h3 className="text-xl font-semibold mb-4 text-[#10313D] Montserrat">APPLY</h3>
              <p className="text-gray-500 Montserratparagraph">
                Start your journey with us by filling out our simple and swift online application form. Once approved, you'll become a part of the BodyRecomp family.
              </p>
            </div>

            <div className="text-center">
              <h3 className="text-xl font-semibold mb-4 text-[#10313D] Montserrat">SHARE</h3>
              <p className="text-gray-500 Montserratparagraph ">
                Post your unique affiliate links across your social media platforms, blog, emails, or wherever else you connect with your audience. The more you share, the greater your outreach.
              </p>
            </div>


            <div className="text-center">
              <h3 className="text-xl font-semibold mb-4 text-[#10313D] Montserrat">EARN</h3>
              <p className="text-gray-500 Montserratparagraph">
                Sit back and watch your passive income grow as you continue to inspire others towards their fitness goals. Your passion for wellness could be your next paycheck with BodyRecomp.
              </p>
            </div>
          </div>
        </div>
      </section>


      <section className="bg-[#031332]">
        <div className="py-10 px-5 container mx-auto lg:py-40">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">

            <div className="text-white">
              <h2 className="Anton400 text-3xl md:text-4xl lg:text-5xl font-bold mb-6 lg:mb-12">
                Join Our Affiliate Team
              </h2>
              <p className="text-white text-base md:text-lg lg:text-xl mb-6 Montserratparagraph lg:w-2/3">
                Be the reason someone starts their fitness journey. Earn and inspire others!
                As a BodyRecomp affiliate, you can be the key to someone’s health transformation.
                Sign up today and turn your passion for fitness into a rewarding venture.
              </p>

              <button
                onClick={()=>navigate("/affiliate-registration")}
                type="button"
                data-aos="fade-up"
                className="Montserrat bg-custom-gradient border-2 border-[#049998] text-white px-4 py-2 rounded-sm hover:text-black transition duration-200"
              >
                Apply Today!
              </button>
            </div>


            <div className="flex justify-center md:justify-end">
              <img
                src="/images/bodyrecomp-logo.png"
                alt="BodyRecomp Logo"
                className="w-40 md:w-48 lg:w-60 h-auto"
              />
            </div>
          </div>
        </div>
        {/* <div className='text-white flex justify-center items-center  border-t border-1 border-white' >
            <p className='Montserratparagraph mt-2 text-[#485D84]'>© Apex Club 2021. All rights reserved</p>
        </div> */}
      </section>

    </>
  )
}

export default BecomeAffiliate