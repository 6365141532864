import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing eye icons from react-icons
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
const AffiliateLogin = () => {
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validate = yup.object().shape({
        username: yup.string()
          .required('Username or Email is required')
          .test(
            'username-or-email',
            'Must be a valid username or email address',
            function (value) {
              const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
              const usernameRegex = /^[a-zA-Z0-9._-]{3,20}$/;
              return emailRegex.test(value) || usernameRegex.test(value);
            }
          ),
        password: yup.string()
          .required('Password is required')
          .min(8, 'Password must be at least 8 characters long')
        //   .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        //   .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        //   .matches(/[0-9]/, 'Password must contain at least one number')
        //   .matches(/[@$!%*?&]/, 'Password must contain at least one special character'),
      });

      const renderError = (message) => (
        <p className="italic text-[#dd1212]">{message}</p>
      );
    

    return (
        <>
        <title>Affiliate Account</title>
        <Header />
        <div>
            <div className='w-full bg-[#F5F5F5] text-black mt-20'>
                <div className='max-w-screen-xl p-10 mx-auto'>
                    <h1 className=' text-[20px] font-[Roboto] text-gray-800 leading-tight md:text-[20px] lg:text-[25px]'>
                        Affiliate Account
                    </h1>
                </div>
            </div>
            <Formik 
              initialValues={{
                username:"",
                password:""
               }}
              enableReinitialize={true}
              validationSchema={validate}
              onSubmit={(values, actions) => {
                console.log("values",values)
              }}
            >

            {({values, setFieldValue,handleChange,handleBlur}) => (
                <Form>
                    <div className='w-full text-black'>
                        <div className='max-w-screen-xl p-10 mx-auto'>
                         
                                <div className="mb-4">
                                    <label htmlFor="username" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                        Username / Email <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="username"
                                        name='username'
                                        value={values.username}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 "
                                        
                                    />
                                    <ErrorMessage name="username" render={renderError} />
                                </div>

                                <div className="mb-4 relative">
                                    <label htmlFor="password" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                        Password <span className='text-danger'>*</span>
                                    </label>
                                    <div className="relative">
                                        <input
                                            type={showPassword ? "text" : "password"} // Dynamically set input type
                                            id="password"
                                            name='password'
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 "
                                            
                                        />
                                        {/* Eye Icon for toggling visibility */}
                                        <div
                                            className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                                        </div>
                                    </div>
                                    <ErrorMessage name="password" render={renderError} />
                                </div>

                             
                                <button
                                    type="submit"
                                    className="bg-custom-gradient text-white px-4 py-2 rounded-sm hover:bg-blue-600 transition duration-200"
                                >
                                    Login
                                </button>
                          

                           
                            <div className="mt-2">
                                <NavLink to="/affiliate-reset-password" className="text-sm text-gray-800 underline">
                                    Lost your password?
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        </div>
        <Footer/>
        </>
    );
};

export default AffiliateLogin;


// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing eye icons from react-icons
// import Header from "../components/Header"
// import Footer from "../components/Footer"
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as yup from "yup";

// const AffiliateLogin = () => {
//     const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

//     const togglePasswordVisibility = () => {
//         setShowPassword(!showPassword);
//     };

//     const validate = yup.object().shape({
//         username: yup.string()
//           .required('Username or Email is required')
//           .test(
//             'username-or-email',
//             'Must be a valid username or email address',
//             function (value) {
//               const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
//               const usernameRegex = /^[a-zA-Z0-9._-]{3,20}$/;
//               return emailRegex.test(value) || usernameRegex.test(value);
//             }
//           ),
//         password: yup.string()
//           .required('Password is required')
//           .min(8, 'Password must be at least 8 characters long')
//           .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
//           .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
//           .matches(/[0-9]/, 'Password must contain at least one number')
//           .matches(/[@$!%*?&]/, 'Password must contain at least one special character'),
//     });

//     const renderError = (message) => (
//         <p className="italic text-[#dd1212]">{message}</p>
//     );

//     return (
//         <>
//         <title>Affiliate Account</title>
//         <Header />
//         <div>
//             <div className='w-full bg-[#F5F5F5] text-black mt-20'>
//                 <div className='max-w-screen-xl p-10 mx-auto'>
//                     <h1 className=' text-[20px] font-[Roboto] text-gray-800 leading-tight md:text-[20px] lg:text-[25px]'>
//                         Affiliate Account
//                     </h1>
//                 </div>
//             </div>
//             <Formik 
//               initialValues={{
//                 username: "",
//                 password: ""
//               }}
//               enableReinitialize={true}
//               validationSchema={validate}
//               onSubmit={(values, actions) => {
//                 console.log("values", values)
//               }}
//             >

//             {({ values, setFieldValue, handleChange, handleBlur }) => (
//                 <Form>
//                     <div className='w-full text-black'>
//                         <div className='max-w-screen-xl p-10 mx-auto'>
//                             <div className="mb-4">
//                                 <label htmlFor="username" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
//                                     Username / Email <span className='text-danger'>*</span>
//                                 </label>
//                                 <input
//                                     type="text"
//                                     id="username"
//                                     name='username'
//                                     value={values.username}
//                                     onChange={handleChange}
//                                     onBlur={handleBlur}
//                                     className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
//                                 />
//                                 <ErrorMessage name="username" render={renderError} />
//                             </div>

//                             <div className="mb-4 relative">
//                                 <label htmlFor="password" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
//                                     Password <span className='text-danger'>*</span>
//                                 </label>
//                                 <div className="relative">
//                                     <input
//                                         type={showPassword ? "text" : "password"} // Dynamically set input type
//                                         id="password"
//                                         name='password'
//                                         value={values.password}
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
//                                     />
//                                     {/* Eye Icon for toggling visibility */}
//                                     <div
//                                         className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
//                                         onClick={togglePasswordVisibility}
//                                     >
//                                         {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
//                                     </div>
//                                 </div>
//                                 <ErrorMessage name="password" render={renderError} />

//                                 {/* Password Generation Instructions */}
//                                 <div className='border border-gray-300 p-4 bg-gray-300 text-white'>
//                                     <p className="mt-2 text-sm text-gray-600">
//                                         Your password must be at least 8 characters long and contain:
//                                         <ul className="list-disc ml-5">
//                                             <li>At least one uppercase letter (A-Z)</li>
//                                             <li>At least one lowercase letter (a-z)</li>
//                                             <li>At least one number (0-9)</li>
//                                             <li>At least one special character (e.g., @, $, !, %, *, ?)</li>
//                                         </ul>
//                                     </p>
//                                 </div>
                                
//                             </div>

//                             {/* Login Button */}
//                             <button
//                                 type="submit"
//                                 className="bg-custom-gradient text-white px-4 py-2 rounded-sm hover:bg-blue-600 transition duration-200"
//                             >
//                                 Login
//                             </button>

//                             {/* Lost your password */}
//                             <div className="mt-2">
//                                 <NavLink to="/" className="text-sm text-gray-800 underline">
//                                     Lost your password?
//                                 </NavLink>
//                             </div>
//                         </div>
//                     </div>
//                 </Form>
//             )}
//             </Formik>
//         </div>
//         <Footer />
//         </>
//     );
// };

// export default AffiliateLogin;


// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import { FaEye, FaEyeSlash, FaInfoCircle } from 'react-icons/fa'; // Importing eye and info icons
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import { Formik, Form, ErrorMessage } from "formik";
// import * as yup from "yup";

// const AffiliateLogin = () => {
//     const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
//     const [showInstructions, setShowInstructions] = useState(false); // State to toggle password instructions

//     const togglePasswordVisibility = () => {
//         setShowPassword(!showPassword);
//     };

//     const toggleInstructionsVisibility = () => {
//         setShowInstructions(!showInstructions);
//     };

//     const validate = yup.object().shape({
//         username: yup.string()
//             .required('Username or Email is required')
//             .test(
//                 'username-or-email',
//                 'Must be a valid username or email address',
//                 function (value) {
//                     const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
//                     const usernameRegex = /^[a-zA-Z0-9._-]{3,20}$/;
//                     return emailRegex.test(value) || usernameRegex.test(value);
//                 }
//             ),
//         password: yup.string()
//             .required('Password is required')
//             .min(8, 'Password must be at least 8 characters long')
//             .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
//             .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
//             .matches(/[0-9]/, 'Password must contain at least one number')
//             .matches(/[@$!%*?&]/, 'Password must contain at least one special character'),
//         });

//     const renderError = (message) => (
//         <p className="italic text-[#dd1212]">{message}</p>
//     );

//     return (
//         <>
//             <title>Affiliate Account</title>
//             <Header />
//             <div>
//                 <div className='w-full bg-[#F5F5F5] text-black mt-20'>
//                     <div className='max-w-screen-xl p-10 mx-auto'>
//                         <h1 className='text-[20px] font-[Roboto] text-gray-800 leading-tight md:text-[20px] lg:text-[25px]'>
//                             Affiliate Account
//                         </h1>
//                     </div>
//                 </div>
//                 <Formik
//                     initialValues={{
//                         username: "",
//                         password: ""
//                     }}
//                     enableReinitialize={true}
//                     validationSchema={validate}
//                     onSubmit={(values) => {
//                         console.log("values", values);
//                     }}
//                 >

//                     {({ values, handleChange, handleBlur }) => (
//                         <Form>
//                             <div className='w-full text-black'>
//                                 <div className='max-w-screen-xl p-10 mx-auto'>
//                                     <div className="mb-4">
//                                         <label htmlFor="username" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
//                                             Username / Email <span className='text-danger'>*</span>
//                                         </label>
//                                         <input
//                                             type="text"
//                                             id="username"
//                                             name='username'
//                                             value={values.username}
//                                             onChange={handleChange}
//                                             onBlur={handleBlur}
//                                             className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
//                                         />
//                                         <ErrorMessage name="username" render={renderError} />
//                                     </div>

//                                     <div className="mb-4 relative">
//                                         <label htmlFor="password" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
//                                             Password <span className='text-danger'>*</span>
//                                         </label>
//                                         <div className="relative">
//                                             <input
//                                                 type={showPassword ? "text" : "password"} // Dynamically set input type
//                                                 id="password"
//                                                 name='password'
//                                                 value={values.password}
//                                                 onChange={handleChange}
//                                                 onBlur={handleBlur}
//                                                 className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
//                                             />
//                                             {/* Eye Icon for toggling visibility */}
//                                             <div
//                                                 className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
//                                                 onClick={togglePasswordVisibility}
//                                             >
//                                                 {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
//                                             </div>
//                                         </div>
//                                         <ErrorMessage name="password" render={renderError} />

//                                         {/* Instructions Toggle */}
//                                         <div className="mt-2 flex items-center text-gray-800 cursor-pointer" onClick={toggleInstructionsVisibility}>
//                                             <FaInfoCircle className="mr-1" size={16} />
//                                             <span className="text-sm underline">
//                                                 {showInstructions ? "Hide" : "Show"} Password Requirements
//                                             </span>
//                                         </div>

//                                         {/* Password Generation Instructions */}
//                                         {showInstructions && (
//                                             <div className='border border-gray-300 p-4 bg-[#222222] text-white mt-2'>
//                                                 <p className="text-sm">
//                                                     Your password must be at least 8 characters long and contain:
//                                                     <ul className="list-disc ml-5">
//                                                         <li>At least one uppercase letter (A-Z)</li>
//                                                         <li>At least one lowercase letter (a-z)</li>
//                                                         <li>At least one number (0-9)</li>
//                                                         <li>At least one special character (e.g., @, $, !, %, *, ?)</li>
//                                                     </ul>
//                                                 </p>
//                                             </div>
//                                         )}
//                                     </div>

//                                     {/* Login Button */}
//                                     <button
//                                         type="submit"
//                                         className="bg-custom-gradient text-white px-4 py-2 rounded-sm hover:bg-blue-600 transition duration-200"
//                                     >
//                                         Login
//                                     </button>

//                                     {/* Lost your password */}
//                                     <div className="mt-2">
//                                         <NavLink to="/" className="text-sm text-gray-800 underline">
//                                             Lost your password?
//                                         </NavLink>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Form>
//                     )}
//                 </Formik>
//             </div>
//             <Footer />
//         </>
//     );
// };

// export default AffiliateLogin;
