import React, { useState } from 'react';

const PaymentForm = () => {
  const [paymentEmail, setPaymentEmail] = useState('');
  const [website, setWebsite] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., save data)
    console.log('Payment Email:', paymentEmail);
    console.log('Website:', website);
  };

  return (
    <div className="border border-gray-300 p-6 w-full">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="paymentEmail" className="block text-sm font-medium text-gray-700 mb-1">
            Payment Email
          </label>
          <input
            type="email"
            id="paymentEmail"
            value={paymentEmail}
            onChange={(e) => setPaymentEmail(e.target.value)}
            required
            className="border border-gray-300 rounded-md p-2 w-full"
            placeholder="Enter your payment email"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="website" className="block text-sm font-medium text-gray-700 mb-1">
            Website
          </label>
          <input
            type="url"
            id="website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            required
            className="border border-gray-300 rounded-md p-2 w-full"
            placeholder="Enter your website URL"
          />
        </div>
        <button
          type="submit"
          className="bg-custom-gradient text-white px-4 py-2 rounded-sm hover:bg-blue-600 transition duration-200"
        >
          SAVE
        </button>
      </form>
    </div>
  );
};

export default PaymentForm;
