import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaEye, FaEyeSlash, FaInfoCircle } from 'react-icons/fa'; // Importing eye icons from react-icons
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import Loader from '../components/Loader';
const AffiliateRegistration = () => {
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility
    const [showInstructions, setShowInstructions] = useState(false); // State to toggle password instructions
    const [isLoading, setisLoading] = useState(false)
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const toggleInstructionsVisibility = () => {
        setShowInstructions(!showInstructions);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Enable smooth scrolling
            });
        }, 100); // Adjust the timeout as needed

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, []);

    const validate = yup.object().shape({
        user_name: yup.string()
            .required("Username is required")
            .matches(/^[a-zA-Z0-9._-]{3,20}$/, "Username must be between 3 and 20 characters and can contain letters, numbers, dots, underscores, and hyphens"),

        first_name: yup.string()
            .required("First name is required")
            .matches(/^[a-zA-Z]+$/, "First name can only contain letters"),

        last_name: yup.string()
            .required("Last name is required")
            .matches(/^[a-zA-Z]+$/, "Last name can only contain letters"),

        email: yup.string()
            .required("Email is required")
            .email("Email must be a valid email address"),

        password: yup.string()
            .required("Password is required")
            .min(8, "Password must be at least 8 characters long")
            .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
            .matches(/[a-z]/, "Password must contain at least one lowercase letter")
            .matches(/[0-9]/, "Password must contain at least one number")
            .matches(/[@$!%*?&]/, "Password must contain at least one special character"),

        confirm_password: yup.string()
            .required("Confirm password is required")
            .oneOf([yup.ref("password")], "Passwords must match"),

        payment_email: yup.string()
            .required("Payment email is required")
            .email("Payment email must be a valid email address"),

        website: yup.string()
            .url("Website must be a valid URL")
            .nullable(),

        agree_to_term: yup.boolean()
            .oneOf([true], 'You must agree to the terms and conditions')
            .required('You must agree to the terms and conditions'),

        how_will_you_promote_us: yup.string()
            .required("This field is required")
            .min(10, "Please provide at least 10 characters")
            .max(250, "At most 250 characters allowed")
    });

    const renderError = (message) => (
        <p className="italic text-[#dd1212]">{message}</p>
    );

    return (
        <>
            <title>Affiliate Registration</title>
            <Header />
            <div>
                <div className='w-full bg-[#F5F5F5] text-black mt-20'>
                    <div className='max-w-screen-xl p-10 mx-auto'>
                        <h1 className='text-[20px] font-[Roboto] text-gray-800 leading-tight md:text-[20px] lg:text-[25px]'>
                            Affiliate Registration
                        </h1>
                    </div>
                </div>

                <Formik
                    initialValues={{
                        user_name: "",
                        first_name: "",
                        last_name: "",
                        email: "",
                        password: "",
                        confirm_password: "",
                        payment_email: "",
                        website: "",
                        agree_to_term: "",
                        how_will_you_promote_us: ""
                    }}
                    enableReinitialize={true}
                    validationSchema={validate}
                    onSubmit={(values, actions) => {
                        console.log("values", values)
                    }}
                >
                    {({ values, setFieldValue, handleChange, handleBlur }) => (
                        <Form>
                            <div className='w-full text-black'>
                                <div className='max-w-screen-xl p-10 mx-auto'>

                                    {/* Username */}
                                    <div className="mb-4">
                                        <label htmlFor="username" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                            Username <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="username"
                                            name='user_name'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
                                        />
                                        <ErrorMessage name="user_name" render={renderError} />
                                    </div>

                                    {/* First Name */}
                                    <div className="mb-4">
                                        <label htmlFor="firstName" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                            First Name <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name='first_name'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
                                        />
                                        <ErrorMessage name="first_name" render={renderError} />
                                    </div>

                                    {/* Last Name */}
                                    <div className="mb-4">
                                        <label htmlFor="lastName" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                            Last Name <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name='last_name'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
                                        />
                                        <ErrorMessage name="last_name" render={renderError} />
                                    </div>

                                    {/* Email */}
                                    <div className="mb-4">
                                        <label htmlFor="email" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                            Email <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            name='email'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
                                        />
                                        <ErrorMessage name="email" render={renderError} />
                                    </div>

                                    {/* Password */}
                                    <div className="mb-4 relative">
                                        <label htmlFor="password" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                            Password <span className='text-danger'>*</span>
                                        </label>
                                        <div className="relative">
                                            <input
                                                type={showPassword ? "text" : "password"} // Dynamically set input type
                                                id="password"
                                                name='password'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
                                            />
                                            {/* Eye Icon for toggling visibility */}
                                            <div
                                                className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                                            </div>
                                        </div>

                                        <div className="mt-2 flex items-center text-gray-800 cursor-pointer" onClick={toggleInstructionsVisibility}>
                                            <FaInfoCircle className="mr-1" size={16} />
                                            <span className="text-sm underline">
                                                {showInstructions ? "Hide" : "Show"} Password Requirements
                                            </span>
                                        </div>
                                        {showInstructions && (
                                            <div className='border border-gray-300 p-4 bg-[#222222] text-white mt-2'>
                                                <p className="text-sm">
                                                    Your password must be at least 8 characters long and contain:
                                                    <ul className="list-disc ml-5">
                                                        <li>At least one uppercase letter (A-Z)</li>
                                                        <li>At least one lowercase letter (a-z)</li>
                                                        <li>At least one number (0-9)</li>
                                                        <li>At least one special character (e.g., @, $, !, %, *, ?)</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        )}
                                        <ErrorMessage name="password" render={renderError} />
                                    </div>

                                    {/* Password Confirmation */}
                                    <div className="mb-4 relative">
                                        <label htmlFor="confirmPassword" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                            Confirm Password <span className='text-danger'>*</span>
                                        </label>
                                        <div className="relative">
                                            <input
                                                type={showConfirmPassword ? "text" : "password"} // Dynamically set input type
                                                id="confirmPassword"
                                                name='confirm_password'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
                                            />
                                            {/* Eye Icon for toggling visibility */}
                                            <div
                                                className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                                                onClick={toggleConfirmPasswordVisibility}
                                            >
                                                {showConfirmPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                                            </div>
                                        </div>
                                        <ErrorMessage name="confirm_password" render={renderError} />
                                    </div>

                                    {/* Payment Email */}
                                    <div className="mb-4">
                                        <label htmlFor="paymentEmail" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                            Payment Email <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            id="paymentEmail"
                                            name='payment_email'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
                                        />
                                        <ErrorMessage name="payment_email" render={renderError} />
                                    </div>

                                    {/* Website */}
                                    <div className="mb-4">
                                        <label htmlFor="website" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                            Website
                                        </label>
                                        <input
                                            type="text"
                                            id="website"
                                            name='website'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
                                        />
                                        <ErrorMessage name="website" render={renderError} />
                                    </div>

                                    {/* How will you promote us? */}
                                    <div className="mb-4">
                                        <label htmlFor="promotion" className="block text-sm font-[Roboto] text-gray-800 mb-0.5">
                                            How will you promote us? <span className='text-danger'>*</span>
                                        </label>
                                        <textarea
                                            id="promotion"
                                            name='how_will_you_promote_us'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="w-full p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2"
                                            rows="4"
                                        />
                                        <ErrorMessage name="how_will_you_promote_us" render={renderError} />
                                    </div>

                                    <div className="mb-4">
                                        <Field
                                            type="checkbox"
                                            id="terms"
                                            name="agree_to_term"
                                            className="p-4"
                                        />
                                        <label htmlFor="terms" className="ml-2 text-sm font-[Roboto] text-gray-800">
                                            <NavLink to="/terms" className="underline">Agree to our Terms and Conditions</NavLink>
                                        </label>
                                        <ErrorMessage name="agree_to_term" render={renderError} />
                                    </div>
                                    {/* Register Button */}
                                    <button
                                        type="submit"
                                        className="bg-custom-gradient text-white px-4 py-2 rounded-sm hover:bg-blue-600 transition duration-200"
                                    >
                                        Register
                                    </button>

                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <Footer />
        </>
    );
};

export default AffiliateRegistration;
