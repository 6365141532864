import React, { useState, useEffect } from 'react';
import PageBanner from '../components/PageBanner';
import { FaRegCalendarCheck } from "react-icons/fa";
import { FaPeopleCarry } from "react-icons/fa";
import { FaChild } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';
import Header from "../components/Header"
import Footer from "../components/Footer"
import axios from 'axios';
import { IMAGE_URL, REACT_APP_API_PORT } from '../Api';
import Loader from '../Loader';

import Aos from 'aos'
import 'aos/dist/aos.css';
const GradientBackground = {
    backgroundImage: 'linear-gradient(45deg, #101010, #383838)',
};


const Training = () => {

    const [data, setData] = useState(null);
    const [imageList, setImages] = useState([]);
    const [error, setError] = useState(null); // State to hold error messages
    const [apiIsLoading, setapiIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setapiIsLoading(true)
                const response = await axios.get(`${REACT_APP_API_PORT}admin/api/cms/training/show/671cdd59d648a6cbfd34ae5d`);
                if (response.data.status) {
                    setapiIsLoading(false)
                    setData(response.data.data);
                   

                    let imagedata = [
                        { src : response.data.data.sectionThreeImageOne , title:response.data.data.sectionThreeImageOneName },
                        { src : response.data.data.sectionThreeImageTwo , title:response.data.data.sectionThreeImageTwoName },
                        { src : response.data.data.sectionThreeImageThree , title:response.data.data.sectionThreeImageThreeName },
                        { src : response.data.data.sectionThreeImageFour , title:response.data.data.sectionThreeImageFourName },
                        { src : response.data.data.sectionThreeImageFive , title:response.data.data.sectionThreeImageFiveName },
                        { src : response.data.data.sectionThreeImageSix , title:response.data.data.sectionThreeImageSixName }
                    ];

                    imagedata = imagedata.map(image => ({
                        src: `${IMAGE_URL}${image.src}`, // Merge IMAGE_URL with the image src
                        title: image.title // Keep the title as it is
                    }));

                    setImages(imagedata)
                
                }
                else {
                    setapiIsLoading(false)
                    setError(null);
                }
            } catch (error) {
                setapiIsLoading(false)
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const [currentImageIndex, setCurrentImageIndex] = useState(null);

  // Open modal with the selected image
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  // Close modal
  const handleCloseModal = () => {
    setCurrentImageIndex(null);
  };

  // Show the previous image
  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? imageList.length - 1 : prevIndex - 1));
  };

  // Show the next image
  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === imageList.length - 1 ? 0 : prevIndex + 1));
  };


  const [rotateStyles, setRotateStyles] = useState(Array(imageList.length).fill({}));

  const handleMouseMove = (index) => (e) => {
    const imageElement = e.currentTarget;
    const rect = imageElement.getBoundingClientRect();
    const x = e.clientX - rect.left; // Mouse X relative to image
    const y = e.clientY - rect.top;  // Mouse Y relative to image
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    
    const rotationY = ((x - centerX) / centerX) * 30; // Adjust rotation sensitivity (Y-axis)
    const rotationX = ((y - centerY) / centerY) * -30; // Adjust rotation sensitivity (X-axis)

    // Update the rotation style for the hovered image
    setRotateStyles((prevStyles) => {
      const newStyles = [...prevStyles];
      newStyles[index] = {
        transform: `rotateX(${rotationX}deg) rotateY(${rotationY}deg) scale(1.05)`,
        transition: 'transform 0.1s ease-out',
      };
      return newStyles;
    });
  };

  const handleMouseLeave = (index) => () => {
    // Reset the transformation for the hovered image
    setRotateStyles((prevStyles) => {
      const newStyles = [...prevStyles];
      newStyles[index] = {
        transform: 'rotateX(0deg) rotateY(0deg) scale(1)',
        transition: 'transform 0.5s ease-in-out',
      };
      return newStyles;
    });
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
    Aos.refresh();
  }, []);

  if (!data) return <p></p>;

    return (
        <>
        <Loader isLoading={apiIsLoading}/>
        <title>Training - Workout Programs For Every Goal, Gym and Home</title>
        <Header/>
            <PageBanner
                heading={data.sectionOneTitle}
                backgroundImage={`${IMAGE_URL}${data.sectionOneBannerImage}`}
            />
            <section className="bg-black pb-10" >
                <div className="container mx-auto p-4 h-full items-center" data-aos="slide-up">
                    <div className="grid grid-cols-1 gap-3 items-center pt-8">
                    <h2 className='text-white font-[Roboto] font-medium leading-tight text-[24px] lg:text-[30px] text-center uppercase'>{data.sectionTwoTitle}</h2>
                    <p className='text-white text-[16px] font-[Roboto] leading-loose mb-1 text-center'>{data.sectionTwoSubTitle}</p>
                    </div>
                </div>
                <div className="container mx-auto p-4 h-full w-full items-center " data-aos="slide-down">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 flex items-center w-full">
                        
                        <div className='flex justify-center flex-col pt-4' style={GradientBackground} data-aos="slide-down">
                            <img src={`${IMAGE_URL}${data.sectionTwoHeadingImageOne}`} alt='mobile' className="w-[58px] m-auto mb-4" />
                            <p className='text-white text-[16px] font-[Roboto] leading-loose mb-4 text-center'>{data.sectionTwoHeadingOne}</p>
                        </div>
                        <div className='flex justify-center text-center flex-col pt-4' style={GradientBackground} data-aos='slide-down'>
                        <img src={`${IMAGE_URL}${data.sectionTwoHeadingImageTwo}`} alt='mobile' className="w-[58px] m-auto mb-4" />
                            <p className='text-white text-[16px] font-[Roboto] leading-loose mb-4 text-center'>{data.sectionTwoHeadingTwo}</p>
                        </div>
                        <div className='flex justify-center flex-col pt-4' style={GradientBackground} data-aos="slide-up">
                        <img src={`${IMAGE_URL}${data.sectionTwoHeadingImageThree}`} alt='mobile' className="w-[58px] m-auto mb-4" />
                            <p className='text-white text-[16px] font-[Roboto] leading-loose mb-4 text-center'>{data.sectionTwoHeadingThree}</p>
                        </div>
                        <div className='flex justify-center flex-col pt-4' style={GradientBackground} data-aos="slide-up">
                        <img src={`${IMAGE_URL}${data.sectionTwoHeadingImageFour}`} alt='mobile' className="w-[58px] m-auto mb-4" />
                            <p className='text-white text-[16px] font-[Roboto] leading-loose mb-4 text-center'>{data.sectionTwoHeadingFour}</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className=" pb-10">
                <div className="container mx-auto p-4 h-full w-full items-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 flex mt-5 w-full">
                        <div className='flex  flex-col pt-4'>
                        <div className="grid grid-cols-1 gap-4 ">
                            <h2 className='text-black font-[Roboto] font-medium leading-tight text-[24px] lg:text-[30px]'>A Training Journey for every goal</h2>
                            <p className='text-[#333333] text-[16px] font-[Roboto] leading-normal mb-1'>Your transformation starts with you completing your daily plan. No more guessing when you step into the gym. We make it easy for you to complete your tasks and stay motivated.</p>
                            <NavLink to="" className='text-center w-full block'> 
                                <button className='text-[16px] uppercase font-[Montserrat] font-bold px-[30px] flex items-center py-[8px] rounded text-white bg-custom-gradient'><FaArrowRight className="mr-1" /> Get your plan</button>
                            </NavLink>
                        </div>
                        </div>
                        <div className='flex justify-center text-center flex-col pt-4'>
                            <div className="container mx-auto">
                          
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {imageList.map((image, index) => (
                                <div
                                    key={index}
                                    className="relative w-full h-[190px] object-cover rounded-lg cursor-pointer"
                                    onClick={() => handleImageClick(index)} // Make sure the click handler is properly attached
                                >
                                    <img
                                    
                                    src={image.src}
                                    alt={image.title}
                                    className="w-full h-full object-cover rounded-lg h-auto transition-transform duration-500 ease-in-out transform group-hover:scale-105"
                                    />
                                 
                                    <div
                                    className="absolute inset-0 flex items-center justify-center opacity-100 hover:opacity-0 transition-opacity duration-300 rounded-lg"
                                    style={{ background: 'linear-gradient(to left, rgba(0, 173, 117, 0.7), rgba(11, 124, 202, 0.7))' }}
                                    >
                                    <p className="text-center text-white italic text-[20px] font-[Roboto]">{image.title}</p>
                                    </div>
                                </div>
                                ))}
                            </div>

                         
                            {currentImageIndex !== null && (
                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                                <div className="relative w-full max-w-3xl mx-auto mx-2">
                                  
                                    <button
                                    className="absolute top-2 right-2 bg-custom-gradient h-[25px] w-[25px] rounded-full leading-[25px] text-white focus:outline-none"
                                    onClick={handleCloseModal}
                                    >
                                    &times;
                                    </button>

                               
                                    <img
                                    src={imageList[currentImageIndex].src}
                                    alt={imageList[currentImageIndex].title}
                                    className="w-full h-auto object-cover rounded-lg"
                                    />

                                    <p className="text-center text-white text-[20px] font-[Roboto] mt-4">
                                    {imageList[currentImageIndex].title}
                                    </p>

                          
                                    <button
                                    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 text-black p-2 rounded-full focus:outline-none"
                                    onClick={handlePrev}
                                    >
                                    &#10094;
                                    </button>

                           
                                    <button
                                    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 text-black p-2 rounded-full focus:outline-none"
                                    onClick={handleNext}
                                    >
                                    &#10095;
                                    </button>
                                </div>
                                </div>
                            )}

                            </div>
                          
                        </div>
                    </div>
                </div>
            </section> */}
         
            <section className="pb-10">
            <div className="container mx-auto p-4 h-full w-full items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-0 flex mt-5 w-full">
                <div className='flex flex-col pt-4'>
                    <div className="grid grid-cols-1 gap-4">
                    <h2 className='text-black font-[Roboto] font-medium leading-tight text-[24px] lg:text-[30px]'>
                    {data.sectionThreeSideHeading}
                    </h2>
                    <p
                        className='text-[#333333] text-[16px] font-[Roboto] leading-loose mb-4 md:text-[18px]'
                        dangerouslySetInnerHTML={{ __html: data.sectionThreeSideDescription }}
                        ></p>
                    <NavLink to={data.sectionThreeButtonLink} className='text-center w-full block'> 
                        <button className='text-[16px] uppercase font-[Montserrat] font-bold px-[30px] flex items-center py-[8px] rounded text-white bg-custom-gradient'>
                        <FaArrowRight className="mr-1" /> {data.sectionThreeButtonName}
                        </button>
                    </NavLink>
                    </div>
                </div>

                <div className='flex justify-center text-center flex-col pt-4'>
                    <div className="container mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {imageList.map((image, index) => (
                        <div
                            key={index}
                            className="relative w-full h-[190px] object-cover rounded-lg cursor-pointer group"
                            onClick={() => handleImageClick(index)}
                            onMouseMove={handleMouseMove(index)} // Pass the index
                            onMouseLeave={handleMouseLeave(index)} // Pass the index
                        >
                            <img
                            src={image.src}
                            alt={image.title}
                            className="w-full h-full object-cover rounded-lg transition-transform duration-500 ease-in-out"
                            style={rotateStyles[index]} // Apply individual rotation style
                            />
                            <div
                            className="absolute inset-0 flex items-center justify-center opacity-100 hover:opacity-0 transition-opacity duration-300 rounded-lg"
                            style={{ background: 'linear-gradient(to left, rgba(0, 173, 117, 0.7), rgba(11, 124, 202, 0.7))' }}
                            >
                            <p className="text-center text-white italic text-[20px] font-[Roboto]">
                                {image.title}
                            </p>
                            </div>
                        </div>
                        ))}
                    </div>

                    {/* Modal */}
                    {currentImageIndex !== null && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                        <div className="relative w-full max-w-3xl mx-auto mx-2">
                            <button
                            className="absolute top-2 right-2 bg-custom-gradient h-[25px] w-[25px] rounded-full leading-[25px] text-white focus:outline-none"
                            onClick={handleCloseModal}
                            >
                            &times;
                            </button>
                            <img
                            src={imageList[currentImageIndex].src}
                            alt={imageList[currentImageIndex].title}
                            className="w-full h-auto object-cover rounded-lg"
                            />
                            <p className="text-center text-white text-[20px] font-[Roboto] mt-4">
                            {imageList[currentImageIndex].title}
                            </p>
                            <button
                            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 text-black p-2 rounded-full focus:outline-none"
                            onClick={handlePrev}
                            >
                            &#10094;
                            </button>
                            <button
                            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 text-black p-2 rounded-full focus:outline-none"
                            onClick={handleNext}
                            >
                            &#10095;
                            </button>
                        </div>
                        </div>
                    )}
                    </div>
                </div>
                </div>
            </div>
            </section>


        <Footer/>
        </>
    )
}

export default Training;