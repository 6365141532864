import React, { useEffect, useState } from 'react';
import { FaHome, FaLink, FaDollarSign, FaChartBar, FaImage, FaWallet, FaCog, FaSignOutAlt } from 'react-icons/fa';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ChartComponent from './components/ChartComponent';
import FilterDateRangeComponent from './components/FilterDateRangeComponent';
import { FiClipboard } from 'react-icons/fi';
import CommissionTable from './components/CommissionTable';
import VisitTable from './components/VisitTable';
import PayoutTable from './components/PayoutTable';
import PaymentForm from './components/PaymentForm';
const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('Dashboard');
  
  const tabs = [
    { name: 'Dashboard', icon: <FaHome /> },
    { name: 'Affiliate Links', icon: <FaLink /> },
    { name: 'Commissions', icon: <FaDollarSign /> },
    { name: 'Visits', icon: <FaChartBar /> },
    { name: 'Creatives', icon: <FaImage /> },
    { name: 'Payouts', icon: <FaWallet /> },
    { name: 'Settings', icon: <FaCog /> },
    { name: 'Logout', icon: <FaSignOutAlt /> },
  ];

  const url = "https://example.com"; // Replace with your URL

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url)
      .then(() => {
        alert('URL copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };



  const renderContent = () => {
    switch (activeTab) {
      case 'Dashboard':
        return <>
        <FilterDateRangeComponent/>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
         <div className="bg-white border border-gray-300  shadow">
          <div className="p-3 border-b border-gray-300">
            <h2 className="text-md font-medium text-gray-600">Visits</h2>
            <p className="mt-1 text-xl font-semibold text-gray-800">1,245</p>
          </div>
          <div className="bg-[#F7F9FB] p-3">
            <a href="#" onClick={() => setActiveTab("Visits")} className="text-gray-500 text-sm underline">View all visits</a>
          </div>
        </div>
       

     
        {/* Commissions Box */}
        <div className="bg-white border border-gray-300  shadow">
          <div className="p-3 border-b border-gray-300">
            <h2 className="text-md font-medium text-gray-600">Commissions</h2>
            <p className="mt-1 text-xl font-semibold text-gray-800">$3,420</p>
          </div>
          <div className="bg-[#F7F9FB] p-3">
            <a href="#"  onClick={() => setActiveTab("Commissions")} className="text-gray-500 text-sm underline">View all commissions</a>
          </div>
        </div>

        {/* Earnings Box */}
        <div className="bg-white border border-gray-300  shadow">
          <div className="p-3 border-b border-gray-300">
            <h2 className="text-md font-medium text-gray-600">Earnings</h2>
            <p className="mt-1 text-xl font-semibold text-gray-800">$12,500</p>
          </div>
          <div className="bg-[#F7F9FB] p-3">
            <a href="#"  onClick={() => setActiveTab("Commissions")} className="text-gray-500 text-sm underline">View all commissions</a>
          </div>
        </div>

      </div>

      <ChartComponent/>
        <div>
            <h2 className='text-gray-600 my-2'>All time</h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
          
            

                <div className="bg-white border border-gray-300  shadow">
                    <div className="py-3 px-4 border-b border-gray-300">
                        <span className="text-xs font-sm text-gray-600">Visits</span>
                        <p className="mt-1 text-sm pb-2 font-semibold text-gray-600">$0</p>
                    </div>
                </div>

                <div className="bg-white border border-gray-300  shadow">
                    <div className="py-3 px-4 border-b border-gray-300">
                        <span className="text-xs font-sm text-gray-600">Commissions</span>
                        <p className="mt-1 text-sm pb-2 font-semibold text-gray-600">$0</p>
                    </div>
                </div>

                <div className="bg-white border border-gray-300  shadow">
                    <div className="py-3 px-4 border-b border-gray-300">
                        <span className="text-xs font-sm text-gray-600">Paid Earnings</span>
                        <p className="mt-1 text-sm pb-2 font-semibold text-gray-600">$0</p>
                    </div>
                </div>

                <div className="bg-white border border-gray-300  shadow">
                    <div className="py-3 px-4 border-b border-gray-300">
                        <span className="text-xs font-sm text-gray-600">Unpaid Earnings</span>
                        <p className="mt-1 text-sm pb-2 font-semibold text-gray-600">$0</p>
                    </div>
                </div>

            </div>
        </div>

        <div>
            <h2 className='text-gray-600 my-2'>Program Details</h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 my-2">
                <div className="bg-white border border-gray-300  shadow">
                    <div className="py-3 px-4 border-b border-gray-300">
                        <span className="text-xs font-sm text-gray-600">Commission Rate</span>
                        <p className="mt-1 text-sm pb-2 font-semibold text-gray-600">Sale rate : 20%</p>
                    </div>
                </div>

                <div className="bg-white border border-gray-300  shadow">
                    <div className="py-3 px-4 border-b border-gray-300">
                        <span className="text-xs font-sm text-gray-600">Cookie Duration</span>
                        <p className="mt-1 text-sm pb-2 font-semibold text-gray-600">30 Days</p>
                    </div>
                </div>
            </div>
        </div>
        </>;
      case 'Affiliate Links':
        return <div className="">
          <div className="border border-gray-300 rounded-md p-4 w-full ">
      <h2 className="text-sm font-medium text-gray-600 mb-2">Your Affiliate Link</h2>
      <p className="text-gray-600 mb-2 text-[10px]">This is your referral URL. Share it with your audience to earn commissions.</p>
      <input
        type="text"
        value={url}
        readOnly
        className="text-gray-600 border border-gray-300 rounded-md p-2 w-full mb-2"
      />
      <div className="flex justify-start">
        <button
          onClick={copyToClipboard}
          className="text-gray-600 flex items-center bg-[#E5E5E5] text-black rounded-md px-4 py-2 hover:bg-gray-300 focus:outline-none"
        >
          <FiClipboard className="mr-2" /> {/* Copy icon */}
          Copy
        </button>
      </div>
          </div>

          <div className="border border-gray-300 rounded-md p-4 w-full my-5 ">
      <h2 className="text-sm font-medium text-gray-600 mb-2">Generate Affiliate Link</h2>
      <p className="text-gray-600 mb-2 text-[10px]">Add any URL from this website in the field below to generate a referral link.</p>
      <input
        type="text"
        placeholder='Paste the link here'
        className="text-gray-600 border border-gray-300 rounded-md p-2 w-full mb-2"
      />
      <div className="flex justify-start">
        <button
          onClick={copyToClipboard}
          className="bg-custom-gradient text-white px-4 py-2 rounded-sm hover:bg-blue-600 transition duration-200"
        >
          GENERATE
        </button>
      </div>
          </div>
        </div>;
      case 'Commissions':
        return <div className="">
          <CommissionTable/>
        </div>;
      case 'Visits':
        return <div className="">
          <VisitTable/>
        </div>;
      case 'Creatives':
        return <div className="">Manage your Creatives here.</div>;
      case 'Payouts':
        return <div className="">
          <PayoutTable/>
        </div>;
      case 'Settings':
        return <div className="">
          <PaymentForm/>
        </div>;
      case 'Logout':
        return <div className="">You have logged out successfully.</div>;
      default:
        return <div className="p-4">Select a tab to view content.</div>;
    }
  };

  return (
    <>
    <title>Affiliate Account</title>
    <Header/>
    <section className=''>
    <div className='w-full bg-[#F5F5F5] text-black mt-20'>
                <div className='max-w-screen-xl p-10 mx-auto'>
                    <h1 className='text-[20px] font-[Roboto] text-gray-800 leading-tight md:text-[20px] lg:text-[25px]'>
                        Affiliate Account
                    </h1>
                </div>
    </div>

    <div className="max-w-screen-xl p-10 mx-auto">
      {/* Tab Navigation */}
      <div className="flex justify-around py-4 border-b border-gray-300">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(tab.name)}
            className={`flex items-center justify-center p-2 space-x-2 transition-colors hover:bg-gray-200  text-gray-600 ${
              activeTab === tab.name ? 'text-blue-600 font-bold border-b-2 border-gray-600' : ''
            }`}
          >
            <span className="text-xl">{tab.icon}</span>
            <span className="hidden sm:inline text-sm">{tab.name}</span>
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="p-4 bg-white">
        {renderContent()}
      </div>
    </div>
    </section>
    <Footer/>
    </>
  );
};

export default Dashboard;
