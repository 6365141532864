import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import axios from 'axios';
import { IMAGE_URL, REACT_APP_API_PORT } from '../Api';


const HomeBanner = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${REACT_APP_API_PORT}admin/api/cms/home/show/6718dad756da10d4a8191fab`);
                if (response.data.status) {
                    setData(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    if (!data) return <p>Loading...</p>;

    return (
        <>
          <section className="w-full h-auto bg-cover bg-center bg-banner  py-[50px] md:py-[20px] md:pb-[100px] " style={{ backgroundImage: `url(${IMAGE_URL}${data.sectionOneBannerImage})` }} >
                <div className="container mx-auto p-4 h-full flex items-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 flex items-center md:pt-[100px]">
                    <div className='order-2 md:order-1'>
                        <h1 className='text-white text-[35px] font-[Roboto] font-medium leading-tight mb-5 md:text-[40px] lg:text-[60px]'>{data.sectionOneTitle}</h1>
                        <p className='text-white text-[16px] font-[Roboto] leading-tight mb-4 md:text-[18px] md:text-[24px]'>{data.sectionOneSubTitle}</p>
                        <div className=''>
                        <NavLink to={data.sectionOneGoogleUrl} target='_black' className="mr-2">
                            <img src={`${IMAGE_URL}${data.sectionOneGoogleImage}`} alt="Play store" className='w-40 max-lg:mr-5 md:w-60' />
                        </NavLink>
                        <NavLink to={data.sectionOneAppleUrl} target='_black'>
                            <img src={`${IMAGE_URL}${data.sectionOneAppleImage}`} alt="App Store" className='w-40 max-lg:mr-5 mb-4 md:w-60' />
                        </NavLink> 
                        </div>
                        <div className='flex mt-4'>
                            <NavLink to={data.sectionOneTwitterUrl} className="hover:scale-80 transition-all duration-300 ease-in-out">
                                <FaTwitter className='text-white text-[25px] mr-5'  />
                            </NavLink>
                            <NavLink to={data.sectionOneFacebookUrl} className="hover:scale-80 transition-all duration-300 ease-in-out">
                                <FaFacebook className='text-white text-[25px] mr-5'  />
                            </NavLink>
                            <NavLink to={data.sectionOneInstagramUrl} className="hover:scale-80 transition-all duration-300 ease-in-out">
                                <FaInstagram className='text-white text-[25px] mr-5'  />
                            </NavLink>
                            <NavLink to={data.sectionOneTiktokUrl} className="hover:scale-80 transition-all duration-300 ease-in-out">
                                <FaTiktok className='text-white text-[25px]'  />
                            </NavLink>
                        </div>
                    </div>
                    
                    <div className="order-1 md:order-2 p-8">
                    <img 
                    src={`${IMAGE_URL}${data.sectionOneSideImage}`}
                    // src="/images/bodyrecomptt.png" 
                    
                    alt="banner image" className='' />
                    </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeBanner;