import React from 'react';

const PayoutTable = () => {
  // Dummy payout data
  const payouts = [
    { id: 1, date: '2024-10-01', amount: '$200.00', status: 'Completed' },
    { id: 2, date: '2024-10-05', amount: '$150.00', status: 'Pending' },
    { id: 3, date: '2024-10-10', amount: '$100.00', status: 'Failed' },
  ];

  // Handle action button click
  const handleActionClick = (id) => {
    alert(`Action for payout ID: ${id}`);
  };

  return (
    <div className="border border-gray-300  w-full">
      {payouts.length === 0 ? (
        <p className="text-gray-600 text-center">You have no payouts.</p>
      ) : (
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-600 text-left">
              <th className="py-2 px-4 border-b">ID</th>
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">Amount</th>
              <th className="py-2 px-4 border-b">Status</th>
              <th className="py-2 px-4 border-b">Action</th>
            </tr>
          </thead>
          <tbody>
            {payouts.map((payout) => (
              <tr key={payout.id} className="border-b hover:bg-gray-100">
                <td className="py-2 px-4">{payout.id}</td>
                <td className="py-2 px-4">{payout.date}</td>
                <td className="py-2 px-4">{payout.amount}</td>
                <td className="py-2 px-4">{payout.status}</td>
                <td className="py-2 px-4">
                  <button
                    onClick={() => handleActionClick(payout.id)}
                    className="bg-custom-gradient text-white px-4 py-2 rounded-sm hover:bg-blue-600 transition duration-200"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PayoutTable;
